import { FaRegUser } from "react-icons/fa";
import { GrNotification } from "react-icons/gr";
import { Link } from "react-router-dom";
import ShowIf from "../../../directive/ShowIf";
export function AppBar({ uname }: { uname?: string }) {
  return (
    <>
      <div className="fixed top-3 left-3">
        <p className="font-semibold text-xl leading-1">
          Welcome
          <ShowIf condition={Boolean(uname)}>
            <span className="font-extrabold text-lg pl-1">
              {uname!.split(" ").at(0)}
            </span>
          </ShowIf>
        </p>
      </div>

      <div className="fixed right-2 top-4 flex space-x-4">
        <Link
          to="/notification"
          className="bg-white h-8 w-8 rounded-full flex items-center justify-center"
        >
          <GrNotification size={20} />
        </Link>
        <Link
          to={"/profile"}
          className="bg-purple-600 h-8 w-8 rounded-full flex items-center justify-center"
        >
          <FaRegUser size={20} />
        </Link>
      </div>
    </>
  );
}
