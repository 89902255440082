

export interface NidOcr {
    nid: string;
    dob: string;
}



export interface NidData {
    requestId: number;
    requestUserId: number;
    requestUserName: string;
    cacheId: number;
    nid: string;
    pin: string;
    dateOfBirth: string;
    nameBn: string;
    nameEn: string;
    bloodGroup: null;
    fatherNameBn: string;
    fatherNameEn: string;
    motherNameBn: string;
    motherNameEn: string;
    spouseNameBn: string;
    spouseNameEn: string;
    occupationEn: string;
    occupationBn: string;
    presentAddressBn: PermanentAddressBn;
    presentAddressEn: PermanentAddressBn;
    permanentAddressBn: PermanentAddressBn;
    permanentAddressEn: PermanentAddressBn;
    gender: string;
    profession: string;
    nationalIdNumber: string;
    oldNationalIdNumber: string;
    createdAt: string;
    photoBase64: any;
}

export interface PermanentAddressBn {
    addressLine: string;
    division: string;
    district: string;
    rmo: string;
    city: string;
    upozila: string;
    unionOrWard: string;
    mouzaOrMoholla: string;
    additionalMouzaOrMoholla: string;
    wardForUnionCouncil: string;
    villageOrRoad: string;
    additionalVillageOrRoad: string;
    houseNo: string;
    postOffice: string;
    postCode: string;
    region: string;
    translated: boolean;
}

export const enum EKYCContext {
    BNPL = 'bnpl',
    IDLE = 'idle',
    Cash = 'cash',
    CreditCard = 'credit-card',
    Sod = 'sod'
}