import {
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { motion } from "framer-motion";
import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import useGoogleAnalytics from "../../analytics/analytics_hook";
import useBoundStore from "../../state/store";
import BottomNav from "../shared/Footer/Footer";
import { pageTranstionVarients } from "./animation";

interface Props {
  title: string;
  showBackButton?: boolean;
  dontShowBottomNav?: boolean;
  scrollRestroration?: boolean;
}
// const pageTransitionVariants = {
//   initial: {
//     opacity: 0,
//     scale: 0.8,
//     rotateY: 90,
//   },
//   animate: {
//     opacity: 1,
//     scale: 1,
//     rotateY: 0,
//     transition: {
//       duration: 0.5,
//       ease: "easeInOut",
//     },
//   },
//   exit: {
//     opacity: 0,
//     scale: 0.8,
//     rotateY: -90,
//     transition: {
//       duration: 0.5,
//       ease: "easeInOut",
//     },
//   },
// };

const TitlePageLayout: FC<PropsWithChildren<Props>> = ({
  title,
  showBackButton = true,

  dontShowBottomNav = false,
  scrollRestroration = true,
  children,
}) => {
  useGoogleAnalytics();
  const navigate = useNavigate();
  const { primaryColor } = useBoundStore();
  return (
    <motion.div
      variants={pageTranstionVarients}
      initial="initial"
      animate="animate"
      exit="exit"

      // variants={pageTranstionVarients}
      // initial="initial"
      // animate="animate"
      // exit="exit"
    >
      {/* {scrollRestroration && <ScrollRestoration />} */}
      <IonHeader>
        <IonToolbar color={"success"}>
          <IonButtons slot="start">
            <div onClick={() => navigate(-1)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 6L9 12L15 18"
                  stroke="url(#paint0_linear_1462_2876)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1462_2876"
                    x1="15"
                    y1="12"
                    x2="9"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.619792" stopColor={"#fff"} />
                    <stop offset="1" stopColor={"#fff"} />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        style={{
          minHeight: "90vh",
          background: `linear-gradient(11.16deg, ${
            primaryColor + 20
          } -25.46%, rgba(1, 128, 129, 0) 46.57%, ${
            primaryColor + 27
          } 144.63%)`,
        }}
      >
        {children}
      </IonContent>
      {!dontShowBottomNav && <BottomNav />}
    </motion.div>
  );
};

export default TitlePageLayout;
