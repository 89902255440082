import { FC } from "react";
import { GiWireframeGlobe } from "react-icons/gi";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { VscVersions } from "react-icons/vsc";

import { useTranslation } from "react-i18next";
import TitlePageLayout from "../../components/Layouts/TitlePageLayout";
import useBoundStore from "../../state/store";
import SupportItem from "./components/SupportItem";

export const AboutUsPagerouteName = "about-us";

export interface IAboutUsPageProps {}

const AboutUsPage: FC<IAboutUsPageProps> = ({}) => {
  const { userConfig } = useBoundStore();

  const { t } = useTranslation("main");
  return (
    <TitlePageLayout title={t("help")} dontShowBottomNav>
      <SupportItem
        title={t("call_center")}
        subtitle={userConfig?.support?.hotline || ""}
        icon=<IoMdCall
          className="text-kGreen-light w-8 h-8 border border-kGreen-light rounded-full p-1"
          onClick={() => {
            (window as any).DanaInterface.onValueReceived(
              "tel",
              userConfig?.support?.hotline
            );
          }}
        />
      />
      <SupportItem
        title={t("support_email")}
        subtitle={userConfig?.support?.email || ""}
        icon=<MdEmail
          className="text-kGreen-light w-8 h-8 border border-kGreen-light rounded-full p-1"
          onClick={() => {
            (window as any).DanaInterface.onValueReceived(
              "mail",
              userConfig?.support?.email
            );
          }}
        />
      />
      <SupportItem
        title={t("website")}
        subtitle={userConfig?.support?.website || ""}
        icon=<GiWireframeGlobe
          className="text-kGreen-light w-8 h-8 border border-kGreen-light rounded-full p-1"
          onClick={() => {
            (window as any).DanaInterface.onValueReceived(
              "web",
              userConfig?.support?.website
            );
          }}
        />
      />
      <SupportItem
        title={t("app_version")}
        subtitle={userConfig?.about.app_version || "1.0.4"}
        icon=<VscVersions className="text-kGreen-light w-8 h-8 border border-kGreen-light rounded-full p-1" />
      />
    </TitlePageLayout>
  );
};
export default AboutUsPage;
