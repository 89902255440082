export enum InputType {
    Box = "box",
    Input = "input",
    Radio = "radio",
    Select = "select",
    File = "file",
    Date = "date",
}
export interface VariableWithDependency extends hasInputType {
    name: string;
    placeholder: string;
    slug: string;
    required: boolean;
    data_type: InputDataType;
    input_type: InputType;
    is_dependent: string;
    priority: string;
    has_dependency: boolean;
    values: string[];
    dependency: Dependency[];
}

export const fromhideToVariableDep = (by: Hide[]): VariableWithDependency[] => {
    return by.map((item) => {
        return {
            ...item,
            has_dependency: false,
            is_dependent: "0",
            dependency: []

        }
    })
}
export enum InputDataType {
    Number = "number",
    Text = "text",
    Url = "url"
}

export function findinputFileField(store: VariableWithDependency[]) {
    let result = []
    for (const item of store) {
        if (item.input_type === InputType.File) result.push(item.slug)
        if (item.has_dependency) {
            for (const depe of item.dependency) {
                for (const sh of depe.show) {
                    if (sh.input_type === InputType.File) result.push(item.slug)
                }
            }
        }

    }
    return result


}
export function findSelectField(store: VariableWithDependency[]) {
    let result: any = {}
    for (const item of store) {
        if (item.input_type === InputType.Select || item.input_type === InputType.Box) result[item.slug] = item.values
        if (item.has_dependency) {
            for (const depe of item.dependency) {
                for (const sh of depe.show) {
                    if (sh.input_type === InputType.Select || item.input_type === InputType.Box) result[item.slug] = item.values[0]
                }
            }
        }
    }
    return result


}
interface hasInputType {
    input_type: InputType
}
export interface Dependency {
    value: string;
    show: Hide[];
    hide: Hide[];
}

export interface Hide extends hasInputType {
    name: string;
    placeholder: string;
    slug: string;
    priority: string;
    required: boolean;
    data_type: InputDataType;
    input_type: InputType;
    values: string[];
}

// has_dependency: boolean;
// dependency: Dependency[];
// is_dependent: string;

// name: string;
// placeholder: string;
// slug: string;
// required: boolean;
// data_type: InputDataType;
// input_type: InputType;
// priority: string;
// values: string[];