import moment from "moment";

export let banglaNumber = new Map<string, string>([
  ["0", "০"],
  ["1", "১"],
  ["2", "২"],
  ["3", "৩"],
  ["4", "৪"],
  ["5", "৫"],
  ["6", "৬"],
  ["7", "৭"],
  ["8", "৮"],
  ["9", "৯"],
]);

const digit = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const getBn = (en: string) => {
  let result = en;
  for (let x in digit) {
    result = result.replace(new RegExp(x, "gu"), banglaNumber.get(x)!!);
  }
  return result;
};

export const getPriceWithComma = (price: any, lang = "") =>
  `£${parseInt(price).toLocaleString("en-GB")}`;

export const formateDateFromUnix = (date: string | undefined | null) => {
  if (date) {
    return moment.unix(+date).format("LL");
  }

  return "_ _ _ /_ _ / _ _";
};
