import React, { PropsWithChildren } from "react";

interface ShowIfProps extends PropsWithChildren {
  condition: boolean;
  fallback?: React.ReactNode;
}

function ShowIf({ condition, children, fallback }: ShowIfProps): JSX.Element {
  return <>{condition ? children : fallback}</>;
}

export default ShowIf;
