
declare global {
    interface Window {
      DanaInterface?: {
        onValueReceived: (type: string, url: string) => void;
      };
      webkit?: {
        messageHandlers: {
          bridge: {
            postMessage: (message: string) => void;
          };
        };
      };
    }
  }
  
 export const usePlatformRedirect = () => {
 
 return (url: string,automaticallyDismiss:boolean = true) => {
        try {
          if (window.DanaInterface) {
            window.DanaInterface.onValueReceived("web", url);
            console.log(`onValueReceived invoked, iwoca with url ${url}`);
          } else if (window.webkit && window.webkit.messageHandlers.bridge) {
            window.webkit.messageHandlers.bridge.postMessage(
              JSON.stringify({
                type: "redirect",
                url: url,
                showDoneButton: automaticallyDismiss,
              })
            );
          } else {
            console.log("Platform not supported for redirect");
          }
        } catch (error) {
          console.log("Error occurred during platform redirect:", error);
        }
      };
    
     
   
  };
  
// Add this line