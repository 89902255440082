import { createBrowserRouter } from "react-router-dom";
import { scoreRoutes } from "../modules/score/routes/route";
import HolderPage from "../pages/HolderPage";
import NewHome from "../pages/NewHome";
import ErrorPage from "../pages/error/Error";
import NotFoundErrorPage from "../pages/error/Error/404";
import { commonRoutes } from "./common";
import { RouterItemEnum } from "./path";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HolderPage />,
      },
      {
        path: "/home",

        element: <NewHome />,
      },
      // ** Score Page
      {
        path: RouterItemEnum.Score,
        children: scoreRoutes,
      },

      ...commonRoutes,
    ],
  },

  {
    path: "*",
    element: <NotFoundErrorPage />,
  },
]);

export default router;
