
import { TRACKING_ID } from "../constants"

import GA4React from "ga-4-react";

function init() {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  const ga4 = new GA4React(
      TRACKING_ID!,
      {
        debug_mode: isDev,
        send_page_view:true
      });

    ga4.initialize().then((ga4) => {
      console.log("ga4", ga4)
      ga4.pageview('Intialize Session')
    }
    ).catch((err) => {
      console.log("err", err)
    }
  )
  
  
}

export function sendEvent({action,label,data,nonInteraction}:{action: string, label: string, data: string, nonInteraction?: boolean | undefined}) {
 
  const isInit = GA4React.isInitialized()
  if(!isInit) init()
  const ga4React = GA4React.getGA4React()
  if(!ga4React) return
  ga4React.gtag('event','module_name',action,label,data,nonInteraction)

  
  


  // ga4React?.event(action,label,data,nonInteraction)
  
  
  // GA4.event(payload)
}



export const sendCustomEvent = (...payload: any) => {
  const isInit = GA4React.isInitialized()
  if(!isInit) init()
  const ga4React = GA4React.getGA4React()
  if(!ga4React) return
  ga4React.gtag('event',...payload)
}

export function sendPageview(path:any) {
  const isInit = GA4React.isInitialized()
  if(!isInit) init()
   const ga4React = GA4React.getGA4React(); 
  if(!ga4React) return
  ga4React.pageview(path,path,path) 
 
}

export default {
  init,
  sendEvent,
  sendPageview,
}