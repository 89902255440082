import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetectorModule from 'i18next-browser-languagedetector';
import en from './en.json';
import bn from './bn.json';

export const resources = {
    en,
    bn
}
export const availabaleLang = Object.keys(resources)
i18n.use(initReactI18next)
  .use(LanguageDetectorModule)
  .init({
    debug: true,
    resources, 
    defaultNS: "common",
    fallbackLng: "en"
  })



  