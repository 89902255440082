import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import httpClient from "../../client/axios";
import TitlePageLayout from "../../components/Layouts/TitlePageLayout";
import { useAutoReqAndCacheResponse } from "../../modules/hook/useClient";
import AsyncValue from "../../modules/shared/AsyncValue";
import BtnPrimary from "../../modules/shared/BtnPrimary";
import { AddressForm } from "./AddressForm";
import { AddressSelect } from "./AddressSelect";

export const AddressCompleterouteName = "/address-complete";

export interface IAddressCompleteProps {}

const AddressComplete: FC<IAddressCompleteProps> = () => {
  const { state } = useLocation();
  const [selectedAddress, setSeletedAddress] = useState<
    Record<string, any> | undefined
  >(undefined);
  const nav = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const submitAddress = (val: Record<string, any>) => {
    setIsLoading(true);
    return httpClient
      .put("/user/address", {
        address_type: "home",
        ...val,
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchAddress = useAutoReqAndCacheResponse(
    "/integration/uk_address/addresses",
    {
      data: state,
    },
    "POST",
    {}
  );

  return (
    <TitlePageLayout title={"Address Complete"} dontShowBottomNav>
      <AsyncValue
        res={fetchAddress}
        onSuccess={function (data: Record<string, any>[]): JSX.Element {
          return (
            <section className="m-4">
              <Formik
                initialValues={{}}
                onSubmit={function (
                  values: FormikValues,
                  formikHelpers: FormikHelpers<FormikValues>
                ): void | Promise<any> {
                  console.log(values);
                  toast.promise(submitAddress(values), {
                    loading: "Submitting address...",
                    success: () => {
                      nav("/home", { replace: true });
                      return "Address submitted!";
                    },
                    error: (e) => `${e.message}`,
                  });
                }}
              >
                <Form>
                  <div className="h-2"></div>
                  <AddressSelect
                    data={data}
                    onSelect={function (e: Record<string, any>): void {
                      setSeletedAddress(e);
                    }}
                  />
                  <div className="h-2"></div>

                  <AddressForm address={selectedAddress} />
                  <div className="h-2"></div>

                  <BtnPrimary
                    center
                    disable={isLoading || !selectedAddress}
                    className="w-full btn btn-pri"
                  >
                    Submit
                  </BtnPrimary>
                </Form>
              </Formik>
            </section>
          );
        }}
      />
    </TitlePageLayout>
  );
};
export default AddressComplete;

{
  /* <div className=" border-y-2 my-2">
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
          <span>By clicking</span>

          <span
            className="font-medium text-purple-600  px-1"
            onClick={() => handleFetchAddress()}
          >
            Fetch Address
          </span>
          <span>
            , we will try to fetch the full address based on the postcode and
            street name you provided.
          </span>
        </p>
      </div>

      <AsyncMutationRequest
        res={req}
        onSuccess={function (data: Record<string, any>[]): JSX.Element {
          return (
            <>
              <Select
                name="address"
                key="address"
                label={"Address"}
                isRequired={true}
                classNames={{
                  base: "my-2",
                  label: "pb-8 font-bold",
                }}
                placeholder={"Select Address"}
                // {...props.fieldProps}

                onChange={(e) => {
                  console.log(e.target.value);
                  setValue(data[parseInt(e.target.value)]);
                }}
                onBlur={() => setTouched(true)}
              >
                {data.map((v, i) => (
                  <SelectItem key={i} value={i}>
                    {v["line_1"]}
                  </SelectItem>
                ))}
              </Select>
            </>
          );
        }}
      /> */
}
