import { ReactNode } from "react"
import { LoaderFunction, RouteObject } from "react-router-dom"

export const makePath = (path?: string, element?: ReactNode, index?: boolean, loader?: LoaderFunction | undefined): RouteObject => {
    return {
        path,
        element,
        index,
        loader
    }
}
export function shuffleArray<T>(array: T[]): T[] {
    const shuffledArray = [...array]; // Create a copy of the original array
  
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
    }
  
    return shuffledArray;
  }
  