import toast from "react-hot-toast";
import { LuBadgeDollarSign } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import useBoundStore from "../../state/store";
import { TenorAmountSelectionRouteName } from "../TesorAmount";
import { OfferCard } from "./components/offerCard";
import { YourOffer } from "./components/YourOffer";
export function SheetContent() {
  const { useStatus, userLastApply } = useBoundStore();
  const nav = useNavigate();

  const handleApply = () => {
    if (useStatus?.score === 0) {
      toast("Please score first");
      return;
    }

    if (Boolean(userLastApply?.selected_offer_id)) {
      toast("You already applied");
      return;
    }

    nav(TenorAmountSelectionRouteName);
  };
  return (
    <section className="mx-4 my-2">
      <div className="h-1"></div>
      <div className="flex items-center border shadow-xl p-2 rounded-xl">
        <div>
          <LuBadgeDollarSign size={25} />
          {/* <SiDiscourse size={25} /> */}
        </div>
        <div className="w-4"></div>
        <section
          className={`flex flex-col space-y-1 ${
            Boolean(userLastApply?.selected_offer_id) ? "opacity-50" : ""
          }`}
          onClick={handleApply}
        >
          <p>Apply for loan</p>
          <p className="text-xs text-gray-500">
            We can accept applications from all businesses
          </p>
        </section>
      </div>

      <div className="h-6"></div>

      {/* Offer */}

      <YourOffer
        offers={userLastApply?.offers}
        selectedOffer={userLastApply?.selected_offer_id}
      />

      {/* Whats new */}

      <div className="h-6"></div>
      <p className="font-bold">Whats New</p>
      <div className="h-4"></div>

      <OfferCard
        onClick={() => toast("You are not eligible for this offer")}
        offerTitle={"Considering an upgraded current account?"}
        label={"Current Accounts"}
        contentText={
          "Open a current account with Kube and enjoy bonuses, cashback, and attractive linked savings options "
        }
        icons={null}
        role="new"
        hint={
          <li className="list-disc font-light text-gray-500 text-sm">
            Create a new account
          </li>
        }
      />

      {/* Card Provider */}

      <div className="h-6"></div>
      <p className="font-bold">
        Suitable for individuals within your score range
      </p>
      <div className="h-4" />

      <div className="bg-teal-50 rounded-lg border shadow-lg">
        <div
          className="flex flex-col p-4 border-b border-b-gray-400 space-y-3"
          onClick={() => toast("you are not eligible for this offer")}
        >
          <div>
            <p className="max-w-[60vw] font-bold">Monzo</p>
            <p className="text-sm text-gray-500">
              25,000 Bonus points T&C apply
            </p>
          </div>
        </div>
        <div
          className="flex flex-col p-4 border-b border-b-gray-400 space-y-3"
          onClick={() => toast("you are eligible for this offer")}
        >
          <div>
            <p className="max-w-[60vw] font-bold">HSBC UK</p>
            <p className="text-sm text-gray-500">
              Up to £250 cashback, T&C company
            </p>
          </div>
        </div>
      </div>
      <div className="h-16" />
    </section>
  );
}
