import { useField, useFormikContext } from "formik";
import { FC, useEffect } from "react";

import { Input } from "@nextui-org/react";
import useBoundStore from "../../../../state/store";
import { Hide, InputType, VariableWithDependency } from "../../types";
import { SelectFormik } from "./DropDown";
import FileInputFormik from "./FileInput";
import { RadioWithLabelFormik } from "./Radio";

export function validateRequired(value: any) {
  let error;
  if (!value) {
    error = "This Field is Required";
  }
  return error;
}

export const DefaultForm: FC<{
  name: string;
  slug: string;
  placeholder: string;
  required?: boolean;
}> = ({ name, slug, placeholder, required }) => {
  // const { setFormTxtValue } = useBoundStore();
  // const [txt, settxt] = useState<string>();
  const [f, meta, helper] = useField(slug);
  return (
    <Input
      key={slug}
      name={slug}
      label={name}
      type="text"
      placeholder={placeholder}
      onBlur={() => helper.setTouched(true)}
      onChange={(e) => helper.setValue(e.target.value)}
    />
  );
};
export interface IFormItemProps {
  formConfig: Omit<VariableWithDependency, "data_type">;
}

const FormikItem: FC<IFormItemProps> = ({ formConfig }) => {
  if (formConfig.has_dependency) {
    return <DependendFormikItem fconfig={formConfig} />;
  }

  switch (formConfig.input_type) {
    case InputType.Radio:
      return (
        <RadioWithLabelFormik
          label={`${formConfig.name}`}
          name={formConfig.slug}
          values={formConfig.values}
          required={formConfig.required}
        />
      );

    case InputType.Box:
      return (
        <SelectFormik
          label={`${formConfig.name}`}
          placeholder={formConfig.placeholder}
          name={formConfig.slug}
          optionLabelWithValue={formConfig.values}
          required={formConfig.required}
        />
      );

    case InputType.Select:
      return (
        <SelectFormik
          label={`${formConfig.name}`}
          placeholder={formConfig.placeholder}
          name={formConfig.slug}
          optionLabelWithValue={formConfig.values}
          required={formConfig.required}
        />
      );
    // case InputType.Input:
    //     return <TextFieldFormik label={`Enter Your ${formConfig.name}`} name={formConfig.slug} required={formConfig.required} />
    case InputType.File:
      return (
        <FileInputFormik
          required={formConfig.required}
          placeholder={formConfig.placeholder}
          label={`Upload your ${formConfig.name}`}
          name={formConfig.slug}
        />
      );
    default:
      return <DefaultForm {...formConfig} />;
  }
  // f.form.setFieldValue(formConfig.name, e.currentTarget.value)
};

{
  /* <Field name={formConfig.name}>
                {(f: FieldProps) => <>
                    <label className='block mb-2 text-sm font-medium text-gray-900'>{formConfig.name} </label>
                    <input onChange={(e) => f.form.setFieldValue(formConfig.name, e.target.value, true)} placeholder={formConfig.placeholder} className='ftxt'
                        // required={formConfig.required}
                        name={formConfig.slug} type={formConfig.data_type} />
                </>}
            </Field> */
}

export const DependendFormikItem: FC<{
  fconfig: Omit<VariableWithDependency, "data_type">;
}> = ({ fconfig }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const filedVal = values[fconfig.slug];
  const { setMutationWithDependency } = useBoundStore();
  // const [dependency, setDependency] = useState<Dependency | undefined
  // >(undefined)

  useEffect(() => {
    const depdny = fconfig.dependency.find((e) => e.value === filedVal);
    console.log(depdny);

    depdny && setMutationWithDependency(depdny);
    depdny?.hide.forEach((e) => {
      setFieldValue(e.slug, undefined);
      setFieldTouched(e.slug, false);
    });

    return () => {
      console.log("Component Unmounted.");

      console.log("Hide fields Restted.");
    };
  }, [values]);
  return (
    <>
      <SelectFormik
        label={fconfig.name}
        name={fconfig.slug}
        optionLabelWithValue={fconfig.values}
        placeholder={fconfig.placeholder}
        required={fconfig.required}
      />
      {/* {dependency && dependency.show.sort((a, b) => parseInt(a.priority) - parseInt(b.priority)).map((e, i) => <FormikItemWithHide key={i} fconfig={e} />)} */}
    </>
  );
};
export const FormikItemWithHide: FC<{
  fconfig: Hide;
  isRequired?: boolean;
}> = ({ fconfig, isRequired }) => {
  switch (fconfig.input_type) {
    case InputType.Radio:
      return (
        <RadioWithLabelFormik
          label={`${fconfig.name}`}
          name={fconfig.slug}
          values={fconfig.values}
          required={fconfig.required}
        />
      );

    case InputType.Box:
      return (
        <SelectFormik
          label={`${fconfig.name}`}
          placeholder={fconfig.placeholder}
          name={fconfig.slug}
          optionLabelWithValue={fconfig.values}
          required={fconfig.required}
        />
      );

    case InputType.Select:
      return (
        <SelectFormik
          label={`${fconfig.name}`}
          placeholder={fconfig.placeholder}
          name={fconfig.slug}
          optionLabelWithValue={fconfig.values}
          required={fconfig.required}
        />
      );
    // case InputType.Input:
    //     return <TextFieldFormik label={`Enter Your ${fconfig.name}`} name={fconfig.slug} required={fconfig.required} />
    case InputType.File:
      return (
        <FileInputFormik
          required={fconfig.required}
          placeholder={fconfig.placeholder}
          label={`Upload your ${fconfig.name}`}
          name={fconfig.slug}
        />
      );
    default:
      return <DefaultForm {...fconfig} />;
  }
};
export default FormikItem;
