import { AxiosRequestConfig } from "axios";
import { Alert, Checkbox, CheckboxProps } from "flowbite-react";
import Parser from "html-react-parser";
import { FC } from "react";
import { useLang } from "../../components/hooks/useLang";
import { GotItModal } from "../../components/shared/Modal";
import { usePlatformRedirect } from "../hook/usePlatformRedirect";
import {
  useRequestGETWithHttpClient,
  useRequestGet,
  useReuqestImmutable,
} from "../hook/useRequest";

export interface ITermsAndConditionsPDFProps extends CheckboxProps {
  url: any;
  params?: AxiosRequestConfig<any> | undefined;
}

const TermsAndConditionsPDF: FC<ITermsAndConditionsPDFProps> = ({
  onChange,
  url,
  params,
}) => {
  const { isLoading, data, error } = useRequestGet<any, any>(url, params);
  const { lang } = useLang();
  if (error) {
    <Alert color="failure">{error.message}</Alert>;
  }

  const EnComp = (
    <>
      <p className="text-sm text-gray-500">I have read and agree to the </p>
      {isLoading ? (
        <span>Loading..</span>
      ) : (
        <a
          href={data?.url ?? ""}
          target="_blank"
          className="text-sm text-kGreen-light"
          rel="noreferrer"
        >
          {" "}
          terms & conditions
        </a>
      )}
    </>
  );

  const BnComp = (
    <>
      <p className="text-sm text-gray-500">আমি </p>
      {isLoading ? (
        <span>Loading..</span>
      ) : (
        <a
          href={data?.url ?? ""}
          target="_blank"
          className="text-sm text-kGreen-light"
          rel="noreferrer"
        >
          {" "}
          শর্তাবলী পড়েছি এবং সম্মত হয়েছি ।
        </a>
      )}{" "}
    </>
  );

  return (
    <div className="flex justify-center">
      <div className="flex items-center space-x-1">
        <Checkbox color="red" onChange={onChange} />
        <div className="flex flex-wrap space-x-1 ">
          {lang.includes("en") ? EnComp : BnComp}
        </div>
      </div>
    </div>
  );
};
export const TermsWithExternalLink = ({
  onChange,
  url,
  params,
}: ITermsAndConditionsPDFProps) => {
  const redirect = usePlatformRedirect();
  const { isLoading, data, error } = useReuqestImmutable<
    Record<string, any>,
    Error
  >(url, params);

  if (error) {
    <Alert color="failure">Something went wrong</Alert>;
  }

  return (
    <div className="flex justify-center">
      <div className="flex items-center space-x-1">
        <Checkbox color="red" onChange={onChange} />
        <div className="flex flex-wrap space-x-1 items-center">
          <p className="text-xs text-gray-500">I have read and agree to the </p>

          {isLoading ? (
            <span>Loading..</span>
          ) : (
            <span
              className="text-kGreen-light text-xs"
              onClick={() => {
                if (data?.terms_url) {
                  console.log(data.terms_url);
                  redirect(data.terms_url);
                }
              }}
            >
              terms and condition
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export const TermsAndConditionHTML = ({
  onChange,
  url,
  params,
}: ITermsAndConditionsPDFProps) => {
  const { isLoading, data, error } = useRequestGETWithHttpClient(
    url,
    true,
    params
  );
  const { isLangEnglish } = useLang();

  if (error) {
    <Alert color="failure">{error.message}</Alert>;
  }

  const EnComp = (
    <>
      <p className="text-xs text-gray-500">I have read and agree to the </p>
      {isLoading ? (
        <span>Loading..</span>
      ) : (
        <GotItModal Body={<div>{Parser(data?.data)}</div>}>
          <p className="text-kGreen-light text-xs">terms and condition</p>
        </GotItModal>
      )}
    </>
  );

  const BnComp = (
    <>
      <p className="text-xs text-gray-500">আমি </p>
      {isLoading ? (
        <span>Loading..</span>
      ) : (
        <GotItModal Body={<div>{Parser(data?.data)}</div>}>
          <div className="flex items-center space-x-1 justify-center">
            <p className="text-kGreen-light text-sm">শর্তাবলী</p>
            <span className="text-xs">পড়েছি এবং সম্মত হয়েছি ।</span>
          </div>
        </GotItModal>
      )}{" "}
    </>
  );

  return (
    <div className="flex justify-center">
      <div className="flex items-center space-x-1">
        <Checkbox color="red" onChange={onChange} />
        <div className="flex flex-wrap space-x-1 items-center">
          {isLangEnglish ? EnComp : BnComp}
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditionsPDF;
