import { FC } from "react";
// import Lottie from 'react-lottie';
// import Lottie from 'react-lottie';
import SvgAnimation from "../../components/shared/SvgAnimation";

import KubeAnim from "../../assets/lottie/kube.json";

const Loading: FC<{ dontTakefullPageWidth?: boolean }> = ({
  dontTakefullPageWidth,
}) => {
  if (dontTakefullPageWidth) {
    return <SvgAnimation animationData={KubeAnim} />;
  }
  return (
    <div className="h-[100vh] flex items-center justify-center">
      <SvgAnimation animationData={KubeAnim} />
    </div>
  );
};
export default Loading;
