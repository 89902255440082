import moment from "moment";
import { FC } from "react";
import ProgressBar from "react-customizable-progressbar";
export function ScoreCircle({
  handleScoreNavigation,
  score,
  lastUpdate,
  score_comment,
}: {
  handleScoreNavigation: () => void;
  score: number;
  lastUpdate: string;
  score_comment: string;
}) {
  return (
    <div
      className=" h-[65vw] w-[65vw] bg-black opacity-30 rounded-full flex items-center justify-center relative"
      onClick={handleScoreNavigation}
    >
      <div className=" h-[60vw] w-[60vw] rounded-full border-4 border-green-600 flex items-center justify-center absolute " />
      <div className="flex-col space-y-4 ">
        <p className="text-lg font-semibold">Affordability Score</p>
        <div className="flex justify-between">
          <p className="text-5xl font-extrabold ">{score}</p>
          <div className="text-right">
            <li className="font-bold text-xl">{score_comment}</li>
            <li className="list-none text-lg">out of 100</li>
          </div>
        </div>

        <p className=" text-center">{moment(lastUpdate).fromNow()}</p>
      </div>
    </div>
  );
}

export interface INewScoreCircleProps {
  handleScoreNavigation: () => void;
  score: number;
  lastUpdate: string;
  score_comment: string;
}

export const NewScoreCircle: FC<INewScoreCircleProps> = ({
  handleScoreNavigation,
  score,
  lastUpdate,
  score_comment,
}) => {
  return (
    <div className="flex flex-col items-center justify-center ">
      <p className="text-xl leading-3 font-bold text-white pt-6 ">
        Affordability Score
      </p>
      <div className="h-1"></div>
      <div className="relative">
        <ProgressBar
          radius={70}
          progress={score}
          initialAnimation
          strokeWidth={6}
          strokeColor="#F11A7B"
          trackStrokeWidth={6}
        />
        <div className="absolute m-auto inset-0 h-max w-max">
          <div className="flex flex-col items-center justify-center ">
            <p className="leading-0 font-extrabold text-4xl">{score}</p>
            <div className="h-2"></div>
            <p className="font-light italic">
              out of <span className="text-xl font-bold not-italic">100</span>
            </p>
            <div className="h-1"></div>
          </div>
        </div>
      </div>
      <div className="h-1"></div>
      <p className="text-xl font-bold">{score_comment} </p>
      <div className="h-1"></div>
      <p className="font-light  italic">
        Updated {moment(lastUpdate).fromNow()}
      </p>
      <div className="flex-1"></div>
    </div>
  );
};
