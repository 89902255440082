import { CSSProperties, FC } from 'react';
import Lottie from 'react-lottie';
import hourGlass from '../../assets/lottie/hour.json';
export interface ISvgAnimationProps {
    loop?: any,
    autoplay?: boolean
    className?: string
    animationData: any
    style?: CSSProperties | undefined,
    preserveAspectRatio?: string // "xMidYMid slice",
    classNameOFComp?: string//"lottie-svg-class",
    //"lottie-svg-id"

}


const SvgAnimation: FC<ISvgAnimationProps> = ({ className, style, loop = true, autoplay = true, animationData = hourGlass, preserveAspectRatio = "xMidYMid slice", classNameOFComp = "lottie-svg-class",
}) => {
    return (
        <div className={className}>
            <Lottie style={style} options={
                {
                    loop, autoplay, animationData, rendererSettings: {
                        preserveAspectRatio,
                        className: classNameOFComp,


                    }
                }
            } />
        </div>
    );
}
export default SvgAnimation
