import { FC } from "react";
import TitlePageLayout from "../../../components/Layouts/TitlePageLayout";
import ForEach from "../../../directive/ForEach";

export const FaqPagerouteName = "faq";

const question = [
  {
    q: "Why Kube?",
    a: `Kube enables you to quickly create and launch various types of credit products, such as credit cards, Buy-Now-Pay-Later and B2B payments. This allows for a full credit operation to be live in a matter of minutes`,
  },
  {
    q: `What does "affordability" mean?`,
    a: `Affordability is about whether you can manage to pay for new credit cards and loans.`,
  },
  {
    q: `How much you can borrow?`,
    a: `Your affordability report shows you what else lenders might think about when deciding how much you can borrow.`,
  },
  {
    q: `You get a score out of 100?`,
    a: `Your score gives you an idea of how well your financial and credit history Impact your affordability score. We'll share insights into what influences your affordability and provide tips on how to improve your score.`,
  },
];

export interface IFaqPageProps {}

const FaqPage: FC<IFaqPageProps> = ({}) => {
  return (
    <TitlePageLayout title={"FAQ"} dontShowBottomNav>
      <section className="m-4 flex flex-col space-y-5">
        <div className="h-4"></div>
        <ForEach
          items={question}
          children={function (item: any): JSX.Element {
            return (
              <div className="flex flex-col space-y-1 border-b pb-3">
                <p className="text-lg font-bold">{item.q}</p>
                <p className="text-gray-500 text-sm">{item.a}</p>
              </div>
            );
          }}
        />
      </section>
    </TitlePageLayout>
  );
};
export default FaqPage;
