import { IonDatetime } from "@ionic/react";
import { Button } from "@nextui-org/react";
import { Modal } from "flowbite-react";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
} from "formik";
import moment from "moment";
import { FC, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import TitlePageLayout from "../../../components/Layouts/TitlePageLayout";
import { useRequestForMutation } from "../../../modules/hook/useRequest";
import { AsyncMutationRequest } from "../../../modules/shared/AsyncValue";
import { AddressCompleterouteName } from "../../AddressComplete";
import { AddressInput } from "./../../NewHome/components/AddressInput";
import { ReactComponent as RegisterIcon } from "./icons/register.svg";
export interface IProfileInputProps {}
export const ProfileInputrouteName = "/profile-input";
const ProfileInput: FC<IProfileInputProps> = () => {
  const { trigger, ...req } = useRequestForMutation("/user");
  const nav = useNavigate();

  return (
    <TitlePageLayout title={"Provide your info"} dontShowBottomNav>
      <section className="flex flex-col mx-4 my-4 overflow-auto">
        <AsyncMutationRequest
          res={req}
          onIdle={
            <div className="overflow-scroll max-h-screen scroll-m-1">
              <div className="h-2"></div>
              <RegisterIcon className="w-full max-h-[30vh]" />
              <div className="flex flex-col justify-center items-center space-y-1 mt-8">
                <p className="text-2xl text-gray-700">Hey There!</p>
                <p className="text-gray-500  text-sm text-center">
                  <span>
                    We are almost there! Before we continue please provide some
                    info!
                  </span>
                </p>
              </div>
              <div className="mt-8 ">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    dob: "",
                    postcode: "",
                    building_name_or_no: "",
                  }}
                  onSubmit={(
                    values,
                    { setSubmitting, resetForm }: FormikHelpers<FormikValues>
                  ) => {
                    trigger(
                      {
                        postBody: {
                          name: values.name,
                          email: values.email,
                          dob: values.dob,
                        },
                      },
                      {
                        onSuccess() {
                          resetForm();
                          nav(AddressCompleterouteName, {
                            state: {
                              postcode: values.postcode,
                              building_name_or_no: values.building_name_or_no,
                            },
                          });
                        },
                        onError() {
                          setSubmitting(false);
                        },
                      }
                    );
                  }}
                >
                  <Form>
                    <Field
                      name={"name"}
                      as={CustomTextInput}
                      label={"Full Name"}
                      required
                    />
                    <Field
                      name={"email"}
                      as={CustomTextInput}
                      label={"Email"}
                      required
                    />
                    {/* Make a custom date picker */}

                    <CustomDatePicker name="dob" label="Date of Birth" />

                    <AddressInput />

                    <Button
                      className="w-full mt-2"
                      type="submit"
                      color="secondary"
                    >
                      Submit
                    </Button>
                  </Form>
                </Formik>
              </div>

              <div className="h-[20vh] w-full "></div>
            </div>
          }
          onSuccess={function (data: any): JSX.Element {
            return <></>;
          }}
        />
      </section>
    </TitlePageLayout>
  );
};

type TextInputProps = {
  label: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CustomTextInput: FC<TextInputProps> = ({ label, ...props }) => {
  return (
    <div className="mb-5">
      <label
        htmlFor={props.name}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {label}
      </label>
      <input
        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
        {...props}
      />
    </div>
  );
};

export const CustomDatePicker = ({
  name,
  label,
}: {
  name: string;
  label: string;
}) => {
  // when click on the input field, it should open a date picker
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div className="mb-5">
          <label
            htmlFor={name}
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {label}
          </label>
          <p
            className=" border-2 w-full p-3 rounded-md"
            onClick={() => setShowDatePicker(true)}
          >
            {field.value ? (
              moment(field.value).format("L")
            ) : (
              <div>Pick a date</div>
            )}
          </p>
          <Modal show={showDatePicker} onClose={() => setShowDatePicker(false)}>
            <Modal.Header>Pick Your Date</Modal.Header>
            <Modal.Body>
              <div className="h-[50vh] w-full">
                <IonDatetime
                  presentation="date"
                  id="datetime"
                  onIonChange={(e) => form.setFieldValue(name, e.detail.value)}
                ></IonDatetime>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => setShowDatePicker(false)}
                color="secondary"
                className="w-full mt-2"
              >
                Done
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </Field>
  );
};

export default ProfileInput;

/* <TitlePageLayout
title="Provide Your Info"
dontShowBottomNav
showBackButton={false}
>
<div className="h-[15vh]"></div>
{showName ? (
  <MakeInputField
    message={"Input your name same as your government approved ID."}
    icon={<NameIcon />}
    state={nameInp}
  />
) : (
  <MakeInputField
    message={"Input your email address."}
    icon={<EmailIcon />}
    state={emailInp}
  />
)}

<div className="h-[10vh]"></div>
{isMutating ? (
  <Loading />
) : (
  <div className="flex items-center justify-center">
    {!showName ? (
      <button
        className="btn-out btn-out-pri"
        onClick={() => navigator(-1)}
      >
        Skip
      </button>
    ) : undefined}

    <div className="w-10"></div>
    <BtnPrimary
      disable={nameInp[0].length < 4}
      onClick={() => {
        if (showName) {
          toggle();
        } else {
          trigger(
            {
              postBody: {
                name: nameInp[0],

                email: emailInp[0],
              },
            },
            {
              onSuccess() {
                navigator(RouterItemEnum.home, {
                  replace: true,
                });
              },
            }
          );
        }
      }}
    >
      {showName ? "Next" : "Submit"}
    </BtnPrimary>
  </div>
)}
</TitlePageLayout> */
