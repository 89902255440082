import { SliceType } from "../../../state";
import {ICardStatus} from '../types';

export interface ICreditCardSlice {
    cardStatus?:ICardStatus
    setCardStatus:(by:ICardStatus)=>void
}

const createCreditCardSlice: SliceType<ICreditCardSlice> = (set) => ({
    setCardStatus(by) {
        set(state=> void(state.cardStatus = by))
    },
    
})

export default createCreditCardSlice;