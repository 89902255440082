import { FC } from "react";

export const HomeIcon: FC<{ color: string, className: string }> = ({ color, className }) => {
    return (<svg id="a" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 501.12 592.28">
        <polygon fill={color} stroke="#transparent" points=".38 450.28 .66 167.82 245.21 308.92 245.21 591.63 .38 450.28"
            strokeMiterlimit="20"
            strokeWidth="2px"
        />
        <polygon fill={color} stroke="transparent" points="11.5 142.05 256.06 283.15 500.38 141.79 256.06 .43 11.5 142.05"
            strokeMiterlimit="20"
            strokeWidth="2px" />
    </svg>)
}