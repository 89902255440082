// make an axios interceptor to refresh token

import axios, { AxiosError, AxiosRequestConfig } from "axios";
import useBoundStore from "../../state/store";
import httpClient from "../axios";
import { API_URL } from "../../constants";

export const onResonposeWith401 = async (err: AxiosError) => {

    // handle 401 error
    if (err.response?.status === 401) {
        // refresh token
        const { userInfo, setUser } = useBoundStore.getState();
        try {
            const req = await axios.post(`${API_URL}/auth/user/token/refresh`, {
                refresh_token: userInfo?.refresh_token,
            });
            
            
            if (req.data.error) {
                console.log("Refresh token expired");
                setUser(undefined);
                localStorage.removeItem("userToken");
                return Promise.reject(req.data.error);
            }
            const newToken = req.data.data.token;
           
            console.log("Refresh token success", req.data.data.token);
            localStorage.setItem("userToken", req.data.data.token);
            setUser({...userInfo!,token:newToken});
            const newRequest =  axios.request({
                ...err.config,
                headers:{
                  ...err.config!.headers,
                  Authorization:`Bearer ${req.data.data.token}`
                }
              })
            
            return newRequest

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if(error.response?.status === 400){
                    console.log("Refresh token error", error.response?.data);
                    setUser(undefined);
                    localStorage.removeItem("userToken");
                    window.location.href = "https://app.dana.money/#/logout";
                }
                return Promise.reject(error.response?.data);
            }
            return Promise.reject(error);
        }
    }
    // handle other errors
    return Promise.reject(err);

};

export const onRequestAddToken = (config:AxiosRequestConfig) => {
    const { userInfo } = useBoundStore.getState();
    if (userInfo?.token) {
        config.headers= {
            ...config.headers,
            Authorization:`Bearer ${userInfo.token}`
        }
        // config.headers.Authorization = `Bearer ${userInfo.token}`;
    }
    return config;
}
