import { useNavigate, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { useGetHttpClient } from "../../client/axios";
import { RouterItemEnum } from "../../router/path";
import { UserInfo } from "../../state";
import useBoundStore from "../../state/store";
export const useInitializeAndHandlePayment = () => {
  const [searchParams] = useSearchParams();
  const { setUser } = useBoundStore();
  const navigator = useNavigate();

  const client = useGetHttpClient();

  const verifyRes = useSWR<UserInfo>(
    searchParams.get("h") ? `userFetch` : null,
    () => {
      return client("/sdk/web/verify", "POST", {
        data: {
          h: searchParams.get("h"),
          cid: searchParams.get("cid"),
        },
      });
    },
    {
      errorRetryCount: 4,
      onSuccess(data) {
        if (data) {
          setUser(data as any);
          localStorage.setItem("userToken", data?.token ?? "");
          navigator(RouterItemEnum.home, { replace: true });
        }
      },
    }
  );

  return { verifyRes };
};

// [Axios][Request] POST /loan/repay/pg/nagad/complete {"status":"Success","payment_ref_id":"MDUyNDEwNTUzNTQ1Mi42ODMwMDIwMDcxMDQyMjUuMjE0MFRTMTA1NTM1LmZlODk3YzFkNGEwZTNkMzNlNGQ5","order_id":"2140TS105535"}

//BKASH PAYMENT /?paymentID=TR0011SY1685436323487&status=cancel&apiVersion=1.2.0-beta
