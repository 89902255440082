import { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import useBoundStore from '../../state/store';

export interface IBtnPrimaryProps extends PropsWithChildren {
    disable?: boolean
    type?: "button" | "submit" | "reset" | undefined
    className?: string
    center?: boolean
    onClick?: VoidFunction
    routeName?: string

}

const BtnPrimary: FC<IBtnPrimaryProps> = ({ disable, children, type, className = 'btn ', center, onClick, routeName }) => {
    const { primaryColor } = useBoundStore()
    const navigate = useNavigate()
    if (center) {
        return <div className='flex items-center justify-center'>
            <button onClick={() => {
                routeName && navigate(routeName)
                onClick && onClick()

            }} type={type} disabled={disable} className={`${className} ${disable && 'cursor-not-allowed opacity-70'}`} style={{
                backgroundColor: primaryColor
            }}>
                {children}
            </button>
        </div>

    }
    return (
        <button onClick={() => {
            routeName && navigate(routeName)
            onClick && onClick()

        }} type={type} disabled={disable} className={`${className} ${disable && 'cursor-not-allowed opacity-70'}`} style={{
            backgroundColor: primaryColor
        }}>
            {children}
        </button>
    );
}
export default BtnPrimary
