import { SliceType } from "../../../state";
import { IUserScoreLevel } from "../types";

export interface IClaimInterface{
        "type": string,
        "branch_id": string,
        "no": string
}
export interface IRewardSlice {
    claimid?:string
    userLevel?:IUserScoreLevel
    setUserLevel:(by:IUserScoreLevel) =>void
    mutateClaimId:(by:string) =>void


}

const createRewardSliceSlice: SliceType<IRewardSlice> = (set) => ({
    claimid:undefined,
    userLevel:undefined,
   mutateClaimId(by) {
       set(state=> void(state.claimid=by))
   },
   setUserLevel(by) {
       set(state=> void(state.userLevel = by))
   },
})

export default createRewardSliceSlice;