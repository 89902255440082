import { FC, useRef } from "react";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import BottomNav from "../../components/shared/Footer/Footer";
import ShowIf from "../../directive/ShowIf";
import AsyncValue from "../../modules/shared/AsyncValue";
import useBoundStore from "../../state/store";
import { SheetContent } from "./SheetContent";
import { Score } from "./components/score";
import { useFetchUserScore } from "./hooks";
export interface INewHomeProps {}

const NewHome: FC<INewHomeProps> = () => {
  const { useStatus } = useBoundStore();

  const fetchScore = useFetchUserScore();
  return (
    <ShowIf
      condition={Boolean(useStatus)}
      fallback={
        <AsyncValue res={fetchScore} onSuccess={(_) => <MainContent />} />
      }
    >
      <MainContent />
    </ShowIf>
  );
};

export default NewHome;

function MainContent() {
  const sheetRef = useRef<BottomSheetRef>(null);
  return (
    <section>
      <Score />
      <BottomSheet
        open
        blocking={false}
        ref={sheetRef}
        snapPoints={({ maxHeight }) => [maxHeight / 1.8, maxHeight * 0.93]}
      >
        <SheetContent />
      </BottomSheet>
      <BottomNav />
    </section>
  );
}
