// import { ReactComponent as MessageIcon } from '../../assets/icons/envelope.svg'

import { ReactComponent as MoreIcon } from "./icons/more.svg";
import { ReactComponent as Notification } from "./icons/notification.svg";

import { useLocation } from "react-router-dom";
import { OfferPagerouteName } from "../../../pages/Offers/pages/main";
import { ReportPagerouteName } from "../../../pages/Report/page/report";
import { RouterItemEnum } from "../../../router/path";
import TabItem from "../TabItem";
import { ReactComponent as ActivityIcons } from "./icons/Activity.svg";
import { HomeIcon } from "./icons/icons";

// style="fill:none; stroke:#231f20; stroke-miterlimit:10; stroke-width:6px;"
const BottomNav = () => {
  const currentPath = useLocation();

  return (
    <section className="fixed inset-x-0 bottom-0 z-10 bg-gray-100 shadow-md flex justify-around">
      <TabItem route={RouterItemEnum.home} title="Home">
        <HomeIcon
          color={`${
            currentPath.pathname === RouterItemEnum.home
              ? "#645faa"
              : " #263238"
          }`}
          className="w-6 h-6"
        />
      </TabItem>
      <TabItem route={"/" + ReportPagerouteName} title="Report">
        <Notification
          className={`w-6 h-6 ${
            currentPath.pathname.includes(ReportPagerouteName)
              ? " stroke-purple-600"
              : "stroke-kGrey"
          } `}
        />
      </TabItem>
      <TabItem route={`/offer--route`} title="Offers">
        <ActivityIcons
          className={`w-6 h-6 ${
            currentPath.pathname.includes(OfferPagerouteName)
              ? " stroke-purple-600"
              : "stroke-kGrey"
          } `}
        />
      </TabItem>
      <TabItem route={RouterItemEnum.More} title="More">
        <MoreIcon
          className={`w-6 h-6 ${
            currentPath.pathname === RouterItemEnum.More
              ? " stroke-purple-600"
              : "stroke-kGrey"
          } `}
        />
      </TabItem>
    </section>
  );
};

export default BottomNav;
