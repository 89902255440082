
import { IFieldShape } from '../modules/score/pages/VariableInput/types'
import { IFormFieldShape } from '../modules/score/pages/ScoringVariable/types/multi'
import { Dependency, VariableWithDependency, fromhideToVariableDep } from '../modules/score/types'
import { SliceType } from './store'

export interface IKeyVal {
    key: string
    val: string
}
export interface IRouterSlice {
    routerCtx: "EarnedWage" | "Loan" | "BNPL" | "Reward",
    changeRouterCtx: (by: "EarnedWage" | "Loan" | "BNPL" | "Reward") => void,
    variableForm: IKeyVal[],
    formTxtValue: any,
    shouldFetchUserStatus?: boolean,
    shouldFetchLoanStatus?: boolean,
    formFieldCollection: IFormFieldShape[],
    setFormFieldsCollection: (by: IFormFieldShape[]) => void,
    formFieldsVariable: VariableWithDependency[],
    setMutationOnFormField: (by: VariableWithDependency[], init?: VariableWithDependency[]) => void,
    allFormFieldsFromApi: IFormFieldShape[],
    setAllFormFieldToApi: (by: IFormFieldShape[]) => void,
    setMutationWithDependency: (by: Dependency) => void,
    setFormTxtValue: (by: string, value: any) => void,
    setVariableForm: (k: string, val: string) => void
}

const createRouterSliceSlice: SliceType<IRouterSlice> = (set, get) => ({
    routerCtx: "Loan",
    changeRouterCtx(by) {
        set(state => void (state.routerCtx = by))
    },
    shouldFetchLoanStatus: true,
    shouldFetchUserStatus: true,
    formFieldsVariable: [],
    formFieldCollection: [],
    allFormFieldsFromApi: [],
    setAllFormFieldToApi(by) {
        set(state => void (state.allFormFieldsFromApi = by))
    },
   
    setFormFieldsCollection(by) {
        set(state => void (state.formFieldCollection = by))
    },
    setMutationOnFormField(by, init) {
        const initial = init ?? get().formFieldsVariable
        const currentFormFields = [...initial, ...by];


        currentFormFields.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))
        // const fieldSet = new Set(currentFormFields.map(item => item.slug)
        const maps = new Map(currentFormFields.map(item => [item['slug'], item]));



        set(state => void (state.formFieldsVariable = Array.from(maps.values())))
    },
    setMutationWithDependency(by) {
        const curForm = get().formFieldsVariable;
        const hideSlug = by.hide.map(it => it.slug);
        const trimForm = curForm.filter(v => !hideSlug.includes(v.slug))
        console.log(trimForm);

        const currentFormFields = [...trimForm, ...fromhideToVariableDep(by.show)];


        currentFormFields.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))
        // const fieldSet = new Set(currentFormFields.map(item => item.slug)
        const maps = new Map(currentFormFields.map(item => [item['slug'], item]));



        set(state => void (state.formFieldsVariable = Array.from(maps.values())))


    },


    variableForm: [],
    formTxtValue: {},
    setFormTxtValue(key: string, value: any) {
        set(state => void (state.formTxtValue[key] = value))

    },


    setVariableForm(k, val) {
        set(function (state) {
            const idx = get().variableForm.findIndex(e => e.key === k)
            if (idx === -1) {
                state.variableForm.push({ key: k, val: val })
            } else {
                state.variableForm[idx] = { key: k, val: val }
            }
        })
    },
})

export default createRouterSliceSlice;