import { ComponentProps, ReactNode } from "react";

interface OfferCardProps extends ComponentProps<"div"> {
  offerTitle: string;
  label: string;
  contentText: string;
  icons: ReactNode;
  role: "offer" | "new";
  hint?: ReactNode;
  footer?: ReactNode;
}

export function OfferCard({
  offerTitle,
  label,
  contentText,
  icons,
  role,
  hint,
  footer,
  ...props
}: OfferCardProps) {
  return (
    <div
      className="min-h-[20vh] min-w-[70vw] shadow-lg rounded-xl border bg-card text-card-foreground  relative"
      {...props}
    >
      <div
        className={`min-h-[11vh] bg-blue-100 p-2 flex items-end rounded-lg ${
          role === "offer" ? "bg-kAmber/40" : "bg-kRed/40"
        }`}
      >
        <p className="font-semibold flex-col sm:mt-4">
          <div className="h-10"></div>
          {offerTitle}

          {hint}
        </p>
      </div>
      <p className=" absolute top-3 left-3 border border-kGrey p-1 max-w-fit text-xs rounded-lg ">
        {label}
      </p>
      {icons}

      <div className="m-4">
        <p className="text-gray-500 text-sm">{contentText}</p>
        <div className="h-3"></div>
        {footer}

        {/* <BtnPrimary center>Apply</BtnPrimary> */}
      </div>
    </div>
  );
}
