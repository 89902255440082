import httpClient from "../../../client/axios"
import { ServerResponse } from "../../../state"
import { IBrands, ICategories, IProduct, ITenureDetails, OrderProduct } from "../types"

export const fetchBrandsFeature = async (isFeatured?: boolean, token?: string) => {
    if (isFeatured) {
        const { data } = await httpClient.get<ServerResponse<IBrands[]>>("/bnpl/products/brands", {
            params: {
                featured: true
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('userToken') ?? token}`
            }
        })
        return data.data
    }
    const { data } = await httpClient.get<ServerResponse<IBrands[]>>("/bnpl/products/brands", {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken') ?? token}`
        }
    })
    return data.data
}


export const fetchProduct = async (params: any = {}, token?: string) => {
    if (params) {
        const { data } = await httpClient.get<ServerResponse<IProduct[]>>("/bnpl/products", {
            params,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('userToken') ?? token}`
            }
        })
        return data.data
    }
    const { data } = await httpClient.get<ServerResponse<IProduct[]>>("/bnpl/products", {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken') ?? token}`
        }
    })
    return data.data
}
export const fetchProductByUrl = async (url: string) => {
    const { data } = await httpClient.get<ServerResponse<IProduct[]>>(url);
    return data.data

}

export const fetchProductById = async (productId: string) => {
    const { data } = await httpClient.get<ServerResponse<IProduct[]>>(`/bnpl/products/${productId}`)
    return data.data
}

export const loanCharge = async (product_id: string, tenor: string) => {
    const { data } = await httpClient.get<ServerResponse<ITenureDetails>>('/bnpl/products/charges', {
        params: {
            product_id,
            tenor
        }
    })
    return data.data
}

export const placeOrder = async (product_id: string, tenor: string, address: string, token?: string) => {
    const { data } = await httpClient.post<ServerResponse<any>>('/bnpl/products/order', {
        product_id,
        tenor,
        address
    }


    )
    if (data.error) {
        throw new Error(data.message)

    }
    return data.data
}
export const fetchOrder = async () => {
    const { data } = await httpClient.get<ServerResponse<OrderProduct[]>>('/bnpl/orders')
    return data.data
}
export const fetchOrderById = async (id: string) => {
    const { data } = await httpClient.get<ServerResponse<OrderProduct[]>>(`/bnpl/orders/${id}`)
    return data.data
}

export const updateOrder = async (product_id: string, tenor: string, id: string) => {
    const { data } = await httpClient.post<ServerResponse<any>>(`/bnpl/products/order/${id}`, {
        product_id,
        tenor
    })
    return data.data

}
export interface IBNPLAgreement {
    url: string;
    asset_id: number;
}


export const generateBnplAgreement = async (orderId: string, token: string) => {
    const { data } = await httpClient.post<ServerResponse<IBNPLAgreement>>(`/user/assets/generate/bnpl/agreement`, {
        "order_id": orderId
    }, {

        headers: {
            'Authorization': `Bearer ${token ?? localStorage.getItem('userToken')}`
        }

    })
    return data.data
}

export const fetchCategory = async () => {
    const { data } = await httpClient.get<ServerResponse<ICategories[]>>(`/bnpl/products/categories`)
    return data.data
}





