import React from "react";
import { IoMdCall } from "react-icons/io";
interface SupportItemProps {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
}

const SupportItem: React.FC<SupportItemProps> = ({
  title = "No title",
  subtitle = "no subtitle",
  icon = (
    <IoMdCall className="w-8 h-8 border border-kGreen-light rounded-full p-1" />
  ),
}) => {
  return (
    <section className="bg-gray-100 flex justify-between items-center p-3 m-4 rounded-lg">
      <div className="flex-col">
        <p className="text-gray-500 font-semibold">{title}</p>
        <p className="font-bold">{subtitle}</p>
      </div>
      {icon}
    </section>
  );
};

export default SupportItem;
