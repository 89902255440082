import { FC } from "react";
import Loading from "../../shared/Loading";
import { useTriggerDirectId } from "./Psycometric";

export const HoldForParsingrouteName = "hold-for-parsing-route";

export interface IHoldForParsingProps {}

const HoldForParsing: FC<IHoldForParsingProps> = () => {
  useTriggerDirectId();

  return (
    <section className="flex flex-col items-center justify-center min-h-screen ">
      <div className="p-1"></div>
      <Loading dontTakefullPageWidth />
      <div className="flex flex-col items-center justify-center space-y-4">
        <h3 className="text-lg font-bold">Cooking your score...</h3>
        <p className="text-center text-gray-500">
          Please wait while you are being redirected to the homepage.
        </p>
      </div>
    </section>
  );
};
export default HoldForParsing;
