import { Field } from "formik";
import { FC } from "react";

interface Props {
  label: string;
  name: string;
  values: string[];
  horizontal?: boolean;
  required?: boolean;
}

export const RadioFormik: FC<{
  name: string;
  value: string;
  label: string;
  required?: boolean;
}> = ({ name, value, label, required }) => {
  return (
    <div className="flex items-center mb-4">
      <Field
        type="radio"
        value={value}
        name={name}
        className="fradio"
        required={true}
        id={value}
      />
      <label
        htmlFor={name}
        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {label}
      </label>
    </div>
  );
};
export const RadioWithLabelFormik: FC<Props> = ({
  label,
  name,
  values,
  horizontal = false,
  required,
}) => {
  return (
    <div>
      <h3 className="block mb-3 text-sm font-medium text-gray-900">
        {label} {required && <span className="text-red-500">*</span>}
      </h3>
      <div className={`flex-wrap gap-6 ${horizontal && "flex-col"}`}>
        {values.map((v, i) => (
          <RadioFormik
            name={name}
            value={v}
            label={v}
            key={i}
            required={required}
          />
        ))}
      </div>
    </div>
  );
};
