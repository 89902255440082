import { setupIonicReact } from "@ionic/react";
import "@ionic/react/css/core.css";
import { AnimatePresence } from "framer-motion";
import { FC, PropsWithChildren, Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { SWRConfig } from "swr";
import analytics from "./analytics/utils";
import { swrMainFetcher } from "./client/axios";
import Loading from "./modules/shared/Loading";
import router from "./router/router";
import useBoundStore from "./state/store";
export interface IMainApplicationProps extends PropsWithChildren {}
setupIonicReact();

const MainApplication: FC<IMainApplicationProps> = () => {
  const { userInfo } = useBoundStore();
  const { i18n } = useTranslation();
  useEffect(() => {
    analytics.init();
  }, [userInfo]);

  useEffect(() => {
    const handleCustomEvent = (event: any) => {
      const argumentValue = event.detail;
      console.log("Received argument:", argumentValue, "event", event);
      // Do something with the argument value
    };
    document.addEventListener("webTest", handleCustomEvent);

    return () => {
      document.removeEventListener("webTest", handleCustomEvent);
    };
  }, [] as const);

  return (
    <Suspense fallback={<Loading />}>
      <SWRConfig
        value={{
          fetcher: (key: string) =>
            swrMainFetcher<any>(key, userInfo?.token, undefined, i18n.language),
        }}
      >
        <Toaster />
        <AnimatePresence>
          <RouterProvider router={router} />
        </AnimatePresence>
      </SWRConfig>
    </Suspense>
  );
};
export default MainApplication;
