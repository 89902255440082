interface ForEachProps {
  items: Array<any>;
  children: (item: unknown) => JSX.Element;
}

function ForEach({ items, children }: ForEachProps): JSX.Element {
  return (
    <>
      {items.map((item, i) => (
        <div key={i}>{children(item)}</div>
      ))}
    </>
  );
}

export default ForEach;
