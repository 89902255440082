import { Card } from "flowbite-react";
import { Field, Form, Formik, FormikValues } from "formik";
import { FC, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { swrMainFetcher } from "../../../client/axios";
import TitlePageLayout from "../../../components/Layouts/TitlePageLayout";
import useBoundStore from "../../../state/store";
import { useReuqestImmutable } from "../../hook/useRequest";
import AsyncValue from "../../shared/AsyncValue";
import BtnPrimary from "../../shared/BtnPrimary";
import { makeScorePath } from "../routes/route";
import { Answer, PsycoMetricData } from "../types";
import { RadioFormik } from "../widgets/FormComp";

export interface IPsycometricProps {}
const parseSource = {
  forSms: "parseSms",
  forDirectId: "parseDirectId",
} as const;
const Psycometric: FC<IPsycometricProps> = () => {
  const { i18n, t } = useTranslation();

  // useTriggerDirectId();

  const psymetricData = useReuqestImmutable<any, any>("/psychometric/exam", {
    headers: {
      "Accept-Language": i18n.language,
    },
  });

  return (
    <TitlePageLayout
      title={t("tell__something__more__about__yourself")}
      dontShowBottomNav
    >
      <div className="mx-8 my-4">
        <ScrollRestoration />
        <AsyncValue<PsycoMetricData, any>
          res={psymetricData}
          onSuccess={function (data: PsycoMetricData): JSX.Element {
            return <PsycometricForm data={data} />;
          }}
        />
      </div>
    </TitlePageLayout>
  );
};

const PsycometricForm: FC<{ data: PsycoMetricData }> = ({ data }) => {
  const navigator = useNavigate();
  const { t } = useTranslation();
  const { userInfo } = useBoundStore();
  return (
    <div>
      <div className="grid grid-cols-1">
        <Formik
          initialValues={{}}
          onSubmit={async (values: FormikValues): Promise<any> => {
            const fdata = Object.values(values);

            ///user/psychometric
            swrMainFetcher("/psychometric/exam", userInfo?.token, {
              postBody: { answers: fdata },
            })
              .then((_) => {
                navigator(makeScorePath("congratulation"));
              })
              .catch((e) => {
                toast.error(e.message);
              });

            // updatePsycometric({ 'answers': fdata }).then(_ => {
            //     navigator("/home")
            // })
          }}
        >
          <Form>
            {data.questions.map(({ answers, title, id }, i) => (
              <div className="my-2">
                <Card>
                  <div key={i}>
                    <p className="font-bold my-3 " cy-value={answers[0].id}>
                      {title}
                    </p>
                    {answers.map((ans) => (
                      <RadioWithImage
                        key={i + 1}
                        ans={ans}
                        name={(i + 1).toString()}
                      />
                    ))}
                  </div>
                </Card>
              </div>
            ))}
            <div className="h-8"></div>
            <BtnPrimary type="submit" center>
              {t("next")}
            </BtnPrimary>
            {/* <div className="flex justify-center items-center ">
                        <button type='submit' className='btn w-1/3 bg-kGreen-light'>Submit</button>
                    </div> */}
          </Form>
        </Formik>
      </div>
    </div>
  );
};
const RadioWithImage: FC<{ ans: Answer; name: string }> = ({ ans, name }) => {
  if (ans.value.startsWith("http")) {
    return (
      <div className="inline-block m-4">
        <Field
          required={true}
          type="radio"
          name={name}
          value={ans.id}
          className="fradio"
          id={ans.id}
        />
        <img className="h-16 w-20" src={ans.value} alt={ans.value} />
      </div>
    );
  }
  return <RadioFormik name={name} value={ans.id} label={ans.value} />;
};

export default Psycometric;

export function useTriggerDirectId() {
  const { userConfig } = useBoundStore();

  useEffect(() => {
    try {
      (window as any).DanaInterface.onValueReceived(
        parseSource.forDirectId,
        userConfig?.score?.parse?.meta?.url || ""
      );
      console.log(
        `onValueReceived invoked, ${parseSource.forDirectId} with url ${userConfig?.score?.parse?.meta?.url}`
      );
    } catch (error) {
      console.log(`onValueReceived error, ${parseSource.forDirectId}`);
    }

    // try {
    //   (window as any).webkit.messageHandlers.bridge.postMessage(
    //     JSON.stringify({
    //       type: "redirect",
    //       url: userConfig?.score?.parse?.meta?.url || "",
    //     })
    //   );
    // } catch (e) {
    //   console.log(e);
    // }
  }, [userConfig?.score?.parse?.meta?.url]);
}
