import { SliceType } from './store';


export interface ThemeSlice {
    primaryColor: string,
    textColor: string,
    changePrimary: (by: string) => void,
}

const createThemeSlice: SliceType<ThemeSlice> = (set) => ({
    primaryColor: "#645faa",
    textColor: "#000000",
    changePrimary: (by: string) => set((state) => void(state.primaryColor = by))
})


export default createThemeSlice;