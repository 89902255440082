import { lazy } from "react";
import { RouterItemEnum } from "../../../router/path";
import CongratulationScorePage, {
  CongratulationScorePagerouteName,
} from "../pages/CongratulationScore";
import HoldForParsing, {
  HoldForParsingrouteName,
} from "../pages/HoldForParsing";
const Psycometric = lazy(() => import("../pages/Psycometric"));
const VariableFormHolder = lazy(() => import("../pages/VariableDependcy"));

const scorePath = {
  new: "new",
  psycometric: RouterItemEnum.Psycometric,
  holdForParsing: HoldForParsingrouteName,
  congratulation: CongratulationScorePagerouteName,
} as const;

export const makeScorePath = (path: keyof typeof scorePath) => {
  return "/score/" + scorePath[path];
};

export const scoreRoutes = [
  {
    path: "new",
    element: <VariableFormHolder />,
  },

  {
    path: RouterItemEnum.Psycometric,

    element: <Psycometric />,
  },
  {
    path: HoldForParsingrouteName,
    element: <HoldForParsing />,
  },
  {
    path: CongratulationScorePagerouteName,

    element: <CongratulationScorePage />,
  },
];
