import { AxiosRequestConfig } from "axios";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { BareFetcher, PublicConfiguration } from "swr/_internal";
import useSWRImmutable from "swr/immutable";
import useMutation, { SWRMutationConfiguration } from "swr/mutation";
import { httpClient, swrMainFetcher } from "../../client/axios";
import useBoundStore from "../../state/store";

export const useRequestForMutation = (
  url: string,
  config?: SWRMutationConfiguration<any, any>
) => {
  const { userInfo } = useBoundStore();
  const postReqFunc = (
    key: string = url,
    {
      arg: { postBody, hasFile, rconfig },
    }: {
      arg: {
        postBody: any;
        hasFile?: boolean;
        rconfig?: AxiosRequestConfig<any> | undefined;
      };
    }
  ) =>
    swrMainFetcher(key, userInfo?.token, {
      isMultipart: hasFile,
      postBody: postBody,
      config: rconfig,
    });
  return useMutation(url, postReqFunc, config);
};

export const useRequestGet = <D, E>(
  url: string,
  params?: AxiosRequestConfig,
  config?: Partial<PublicConfiguration<D, E, BareFetcher<D>>> | undefined
) => {
  const { userInfo } = useBoundStore();
  const { i18n } = useTranslation();
  return useSWR<D, E>(
    url,
    () =>
      swrMainFetcher(
        url,
        userInfo?.token,
        {
          config: params,
        },
        i18n.language
      ),
    config
  );
};

export const useRequestGetForMutation = (
  url: string,
  config?: SWRMutationConfiguration<any, any>
) => {
  const { userInfo } = useBoundStore();
  const { i18n } = useTranslation();
  const getReqFunc = (
    key: string = url,
    { arg: { config } }: { arg: { config: AxiosRequestConfig } }
  ) =>
    swrMainFetcher(
      key,
      userInfo?.token,
      {
        config,
      },
      i18n.language
    );
  return useMutation(url, getReqFunc, config);
};
// 01850022090

export const useReuqestImmutable = <D, E>(
  url: string,
  params?: AxiosRequestConfig,
  config?: Partial<PublicConfiguration<D, E, BareFetcher<D>>> | undefined
) => {
  const { userInfo } = useBoundStore();
  const { i18n } = useTranslation();
  return useSWRImmutable<D, E>(
    url,
    () =>
      swrMainFetcher(
        url,
        userInfo?.token,
        {
          config: params,
        },
        i18n.language
      ),
    config
  );
};

export const useRequestGETWithHttpClient = (
  url: any,
  requestOnce: boolean,
  config?: AxiosRequestConfig
) => {
  const { userInfo } = useBoundStore();
  const request = httpClient.get(url, {
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
    },
    ...config,
  });

  return useSWR(url, () => request, {
    revalidateIfStale: !requestOnce,
    revalidateOnFocus: !requestOnce,
    revalidateOnReconnect: !requestOnce,
  });
};
