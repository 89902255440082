import { AxiosRequestConfig, Method } from "axios";
import { SWRConfiguration, BareFetcher, Key } from "swr";
import { useGetHttpClient } from "../../client/axios";
import useMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";
import useSWR from "swr";

export const useRequestManualTrigger = <T extends any>(
  url: string,
  reqconfig?: AxiosRequestConfig,
  method?: Method,
  key?: Key
) => {
  const client = useGetHttpClient();
  const reqFunction =  (
    key: Key,
    {arg:{config}}: {arg: {config: AxiosRequestConfig}},
  ) =>  client(url, method, config);
    
  const cacheKey = key || url;
  return useMutation(cacheKey, reqFunction);
};
export const useRequestManualTriggerForMultipart = <T extends any>(
  url: string,
  method?: Method,
  key?: Key
) => {
  const client = useGetHttpClient(true);
  const reqFunction =  (
    key: Key,
    {arg:{config}}: {arg: {config: AxiosRequestConfig}},
  ) =>  client(url, method, config);
    
  const cacheKey = key || url;
  return useMutation(cacheKey, reqFunction);
};
export const useAutoReqAndCacheResponse = <D, E>(
  url: string,
  config?: AxiosRequestConfig,
  method?: Method,
  resonfig?: Partial<SWRConfiguration<D, E, BareFetcher<D>>> | undefined,
  key?: Key
) => {
  const client = useGetHttpClient();
  const cacheKey = key || url;
  return useSWRImmutable<D, E>(
    cacheKey,
    () => client(url, method, config),
    resonfig
  );
};
export const useAutoRequest = <D, E>(
  url: string,
  config?: AxiosRequestConfig,
  method?: Method,
  resonfig?: Partial<SWRConfiguration<D, E, BareFetcher<D>>> | undefined,
  key?: Key
) => {
  const client = useGetHttpClient();
  const cacheKey = key || url;
  return useSWR<D, E>(cacheKey, () => client(url, method, config), resonfig);
};
