import { ComponentProps, PropsWithChildren, ReactNode } from "react";
import ShowIf from "../../../directive/ShowIf";

interface Props extends PropsWithChildren, ComponentProps<"div"> {
  title: string;
  content: string;
  trailing?: ReactNode;
  onClick?: () => void;
}

export function OfferListTile({
  children,
  title,
  content,
  trailing,
  ...restprops
}: Props) {
  return (
    <div
      className="-mx-2 flex items-start space-x-4 rounded-md p-2 transition-all hover:bg-accent hover:text-accent-foreground "
      {...restprops}
    >
      {children}
      {/* <FaHandHoldingUsd className="mt-px h-8 w-8 text-kGreen-light/50 " /> */}
      <div className="space-y-1  flex-1 px-2 border-b-2">
        <div className="flex">
          <div>
            <p className=" font-medium leading-none">{title}</p>
            <p className="text-gray-500 text-muted-foreground text-sm">
              {content}
            </p>
          </div>
          <ShowIf condition={Boolean(trailing)}>
            <div className="flex-1" />
            {trailing}
          </ShowIf>
        </div>
        <div className="h-1"></div>
      </div>
    </div>
  );
}
