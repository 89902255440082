import { Field } from "formik";
import { CustomTextInput } from "../Profile/pages/ProfileInput";

export function AddressForm({
  address,
}: {
  address: Record<string, any> | undefined;
}) {
  if (!address) {
    return <section></section>;
  }

  return (
    <section>
      <div className="flex flex-col space-y-2">
        {Object.entries(address).map(([key, value]) => (
          <Field
            key={key}
            name={key}
            as={CustomTextInput}
            label={key
              .split("_")
              .join(" ")
              .replace(/^\w/, (c) => c.toUpperCase())}
            required={["postcode", "line_1", "country"].includes(key)}
          />
        ))}
      </div>
    </section>
  );
}
