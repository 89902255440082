import { FC, PropsWithChildren } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useBoundStore from '../../state/store';

export interface IAdaptBoxProps {
    isLink?: boolean
    to?: string
    onClick?: VoidFunction
    goRoute?: string
    gradientPrimary?: boolean
    onlyTxt?: boolean
    textPrimary?: boolean
    onlyBg?: boolean
    classNames?: string,
    disable?: boolean
}

const AdaptBox: FC<PropsWithChildren<IAdaptBoxProps>> = ({ goRoute, onClick, isLink, to, gradientPrimary, children, textPrimary, onlyBg, onlyTxt, classNames, disable }) => {
    const { primaryColor, textColor } = useBoundStore()
    const navigator = useNavigate()
    if (onlyBg) {
        return <div onClick={() => {
            onClick && onClick()
            goRoute && navigator(goRoute)
        }} className={classNames + `${disable && 'cursor-not-allowed'}`} style={{
            backgroundColor: `${disable ? primaryColor + '60' : primaryColor}`

        }}>{children}</div>
    }


    if (gradientPrimary) {
        return isLink ? <Link to={to ?? "/"} className={classNames} style={{
            background: `linear-gradient(to bottom,  ${primaryColor} 20%,${primaryColor} 80%)`
        }}>{children}</Link> : <div onClick={goRoute ? () => { navigator(goRoute) } : onClick} className={classNames} style={{
            background: `linear-gradient(to bottom,  ${primaryColor} 20%,${primaryColor} 80%)`
        }}>{children}</div>
    }

    if (textPrimary) {
        return isLink ? <Link to={to ?? "/"} className={classNames} style={{
            color: primaryColor
        }}> {children}  </Link> : <div onClick={goRoute ? () => { navigator(goRoute) } : onClick} className={classNames} style={{
            color: primaryColor
        }}> {children}  </div>
    }
    if (onlyTxt) {
        return isLink ? <Link to={to ?? "/"} className={classNames} style={{
            color: "#000000"
        }}> {children}  </Link> : <div onClick={goRoute ? () => { navigator(goRoute) } : onClick} className={classNames} style={{
            color: "#000000"
        }}> {children}  </div>
    }

    return (
        <div onClick={goRoute ? () => { navigator(goRoute) } : onClick} className={classNames} style={
            {
                backgroundColor: primaryColor,
                color: "#000000"
            }
        }>
            <p>{primaryColor} - {textColor}</p>
            {children}
        </div>
    );
}

export default AdaptBox
