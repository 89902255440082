import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { sDone } from "../../../assets/assets";
import useBoundStore from "../../../state/store";
import { useTranslation } from 'react-i18next';

interface MonthOptionProps {
    isActive?: boolean,
    textClass?: string,
    optionTitle: string,
    onClick?: VoidFunction
}
export const MonthOption: FC<MonthOptionProps> = ({ isActive = false, textClass = 'text-kGreen-light', onClick = () => { }, optionTitle }) => {
    const { primaryColor } = useBoundStore()

    if (isActive) return (<div onClick={onClick} className="px-4 py-1  rounded-2xl border shadow-sm text-white" style={{ backgroundColor: primaryColor }}>
        <div className="flex space-x-3 items-center justify-between">
            <div>{sDone}</div>
            <div >{optionTitle}</div>
        </div>
    </div>)
    return (<div onClick={onClick} className={`px-8 py-1 bg-gray-50 rounded-2xl border shadow-sm ${textClass}`}>{optionTitle}</div>);
}

const TenureAndMonth: FC = () => {
    const [month, setmonth] = useState<'3' | '6' | '9' | '12' | undefined>('3')
    const { fetchTenureByMonth } = useBoundStore()
    const { t } = useTranslation()
    const handleSelect = (mon: '3' | '6' | '9' | '12') => {
        setmonth(mon)
        fetchTenureByMonth(mon)
    }
    useEffect(() => {
        fetchTenureByMonth(month as string)

        return () => {

        }
    }, [])
    return <div className="my-3">
        <p className='text-kGrey mx-3 my-3 '>{t('tenor__months')}</p>
        <div className="flex justify-around">
            <MonthOption optionTitle={'3'} isActive={month === '3'} onClick={() => handleSelect('3')} />
            <MonthOption optionTitle={'6'} isActive={month === '6'} onClick={() => handleSelect('6')} />
            <MonthOption optionTitle={'9'} isActive={month === '9'} onClick={() => handleSelect('9')} />
            <MonthOption optionTitle={'12'} isActive={month === '12'} onClick={() => handleSelect('12')} />
        </div>
    </div>
}

export default TenureAndMonth