import { FC } from "react";
import { CiGift } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import ThankYouAnim from "../../../assets/lottie/thank.json";
import SvgAnimation from "../../../components/shared/SvgAnimation";
import { OfferCard } from "../../../pages/NewHome/components/offerCard";
import { TenorAmountSelectionRouteName } from "../../../pages/TesorAmount";
import useBoundStore from "../../../state/store";
import { getPriceWithComma } from "../../../utils/en2bn";
import AsyncValue from "../../shared/AsyncValue";
import BtnPrimary from "../../shared/BtnPrimary";
import { ICommonUserStatus } from "../types";
export interface ICongratulationScorePageProps {}
export const CongratulationScorePagerouteName = "score-done";

const CongratulationScorePage: FC<ICongratulationScorePageProps> = () => {
  const { setUserStaus } = useBoundStore();
  const creditReq = useSWR("/user/status");

  return (
    <AsyncValue<ICommonUserStatus, any>
      res={creditReq}
      onSuccess={function (data: ICommonUserStatus): JSX.Element {
        setUserStaus(data);
        return <CongratulationSection data={data} />;
      }}
    />
  );
};
export default CongratulationScorePage;

function CongratulationSection({ data }: { data: ICommonUserStatus }) {
  const nav = useNavigate();
  return (
    <section className="h-screen w-full flex flex-col justify-center items-center">
      <div className="flex-1"></div>
      <div className="flex-1"></div>
      <SvgAnimation animationData={ThankYouAnim} loop={3} />

      <div className="h-8"></div>

      <div className="text-center">
        <h1 className="text-2xl font-bold">Congratulations!</h1>
        <div className="h-2"></div>
        <p className=" text-base text-gray-500 mx-6">
          Your affordability score is
          <span className="text-xl font-bold"> {data.score}</span> out of 100.
        </p>
      </div>
      <div className="flex-1"></div>
      <OfferCard
        offerTitle={"Loan Offers"}
        label={"iwoca"}
        contentText={`Get up to ${getPriceWithComma(
          "500000"
        )} for upcoming purchases`}
        role="offer"
        onClick={() => {
          nav(TenorAmountSelectionRouteName, { replace: true });
        }}
        icons={
          <p className=" absolute top-3 right-3 flex ">
            <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center">
              <CiGift size={25} />
            </div>
          </p>
        }
      />
      <div className="flex-1"></div>
      <div className="flex-1"></div>
      <div className="flex-1"></div>
      <BtnPrimary center routeName="/home">
        Go to Home
      </BtnPrimary>
      <div className="flex-1"></div>
    </section>
  );
}
