import {  Variants } from "framer-motion";



export const pageTranstionVarients: Variants = {
    initial: { opacity: 0 ,zoom:0.8,transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        mass: 1
    }},
    animate: { opacity: 1,zoom:1,transition:{
        type: "spring",
        stiffness: 260,
        damping: 20,
        mass: 1
    } },
    exit: { opacity: 0,zoom:1.1,transition:{
        type: "spring",
        stiffness: 260,
        damping: 20,
        mass: 1
    }
     },
  };
// {
//     initial: {
//         opacity: 0,
//         zoom: 0.8,
        // transition: {
        //     type: "spring",
        //     stiffness: 260,
        //     damping: 20,
        //     mass: 1
        // }


//     },
//     animate: {
//         opacity: 1, zoom: 1, transition: {
//             type: "spring",
//             stiffness: 260,
//             damping: 20,
//             mass: 1
//         }
//     },
//     exit: {
//         opacity: 0,
//         zoom: 1.1,
//         // transition: {
//         //     type: "spring",
//         //     stiffness: 260,
//         //     damping: 20,
//         //     mass: 1
//         // }
//     },
// }