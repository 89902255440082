import { SliceType } from "../../../state";
import { Account } from "../pages/Account/types";
import { EmployeeStatus } from "../types";

export interface IsodSlice {
    employeeStatus?: EmployeeStatus,
    setEmpStatus: (by: EmployeeStatus) => void;
    sodRequest?: {
        amount: string,
        account: Account
    },
    setSodRequest: (by: {
        amount: string,
        account: Account
    }) => void

}

const createSodSlice: SliceType<IsodSlice> = (set) => ({
    setEmpStatus(by) {
        set((state) => void (state.employeeStatus = by))
    },
    setSodRequest(by) {
        set(state => void (state.sodRequest = by))

    },
})

export default createSodSlice;
