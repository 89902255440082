import create, { StateCreator } from "zustand";
import createThemeSlice, { ThemeSlice } from "./ThemeSlice";
import { devtools, persist } from 'zustand/middleware'
import { immer } from "zustand/middleware/immer";
import createRouterSliceSlice, { IRouterSlice } from "./RouterSlice";
import createUserSlice, { IUserSlice } from "./UserSlice";
import createPaylaterSliceSlice, { IPaylaterSlice } from "../modules/paylater/state/PaylaterSlice";
import createEkycSliceSlice, { IEkycSlice } from "../modules/ekyc/state/EkycSlice";

import { IRewardSlice } from "../modules/reward/state";
import createRewardSliceSlice from "../modules/reward/state/reward.slice";
import { ICreditCardSlice } from "../modules/CreditCard";
import createCreditCardSlice from "../modules/CreditCard/state/creditcard.slice";
import createSodSlice, { IsodSlice } from "../modules/sod/state";
import createFormStateSlice, { IScoreForm } from "../modules/score/pages/ScoringVariable/state";




export type SliceType<T> = StateCreator<MyStoreType, [["zustand/immer", never], ["zustand/devtools", never], ["zustand/persist", unknown]], [], T>

const NONPersistKEY = ['formTxtValue', 'formFieldsVariable', 'variableForm']

export type MyStoreType = ThemeSlice & IRouterSlice & IUserSlice & IPaylaterSlice & IEkycSlice & IRewardSlice & ICreditCardSlice & IsodSlice & IScoreForm 

const useBoundStore = create<MyStoreType>()(
    immer(
        devtools(
            persist(
                (...a) =>
                (
                    {
                        ...createThemeSlice(...a),
                        ...createRouterSliceSlice(...a),
                        ...createUserSlice(...a),
                        ...createSodSlice(...a),
                        ...createPaylaterSliceSlice(...a),
                        ...createEkycSliceSlice(...a),
                        ...createRewardSliceSlice(...a),
                        ...createCreditCardSlice(...a),
                        ...createFormStateSlice(...a),
                       

                    }
                ),{
                    name: 'dana-store',
                    partialize(state) {
                        return Object.fromEntries(Object.entries(state).filter(([key]) => !NONPersistKEY.includes(key)))
                    },
    
                }
            )
        )
    )
)

export const getUserInfo = () => useBoundStore.getState().userInfo
// how to make custom event and recive it

const eventFromAndroid = ()=> {
    
}


export default useBoundStore;