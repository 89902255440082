/**
 * AddressInput component
 *
 * it render postcode and street name as textfield. then it call api to get full address and render it as dropdown
 *
 * @component
 * @example
 * return (
 *   <AddressInput />
 * )
 */

import { Field, useField, useFormikContext } from "formik";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRequestForMutation } from "../../../modules/hook/useRequest";
import { CustomTextInput } from "../../Profile/pages/ProfileInput";

export function AddressInput() {
  const { values } = useFormikContext<Record<string, any>>();

  const { postcode, building_name_or_no, address } = values;

  const [f, _, { setTouched, setValue, ...resthelper }] = useField("address");
  const req = useRequestForMutation("/integration/uk_address/addresses");

  const handleFetchAddress = useCallback(() => {
    // validate postcode and building_name_or_no
    if (!postcode || !building_name_or_no) {
      toast.error("Please provide postcode and street name");
      return;
    }
    req.trigger({
      postBody: {
        postcode,
        building_name_or_no,
      },
    });
  }, [postcode, building_name_or_no, req]);

  return (
    <>
      <Field
        name={"postcode"}
        as={CustomTextInput}
        label={"Postcode"}
        required
      />

      <Field
        name={"building_name_or_no"}
        as={CustomTextInput}
        label={"Street"}
        // onBlur={handleBlur()}
        required
      />
    </>
  );
}
