import { t } from "i18next";
import { lazy } from "react";
import { Link, RouteObject } from "react-router-dom";
import TitlePageLayout from "../components/Layouts/TitlePageLayout";
import AddressComplete, {
  AddressCompleterouteName,
} from "../pages/AddressComplete";
import FaqPage, { FaqPagerouteName } from "../pages/Faq/pages/main";
import { AboutUsPagerouteName } from "../pages/Help";
import OfferPage, { OfferPagerouteName } from "../pages/Offers/pages/main";
import { EditProfilerouteName } from "../pages/Profile/pages/EditProfile";
import { ProfileInputrouteName } from "../pages/Profile/pages/ProfileInput";
import ReportPage, { ReportPagerouteName } from "../pages/Report/page/report";
import TenorAmountSelection, {
  TenorAmountSelectionRouteName,
} from "../pages/TesorAmount";
import { RouterItemEnum } from "./path";
import { makePath } from "./utils";
const MorePage = lazy(() => import("../components/pages/More/page"));
const AboutUsPage = lazy(() => import("../pages/Help"));

const Rescore = lazy(() => import("../pages/Home/components/Rescore"));
const HowDanaWorks = lazy(() => import("../pages/HowDanaWorks/HowDanaWorks"));
const InitRoute = lazy(() => import("../pages/InitRoute"));
const EditProfile = lazy(() => import("../pages/Profile/pages/EditProfile"));
const ProfileInput = lazy(() => import("../pages/Profile/pages/ProfileInput"));
const ProfileMainPage = lazy(
  () => import("../pages/Profile/pages/ProfileMain")
);
const StateMentMain = lazy(
  () => import("../pages/Statements/pages/StateMentMain")
);
const WhatIsCreditScore = lazy(() => import("../pages/WhatIsCreditScore/page"));
const NotificationPage = lazy(
  () => import("../pages/Notification/pages/NotificationPage")
);

export const commonRoutes: RouteObject[] = [
  { path: RouterItemEnum.Rescore, element: <Rescore /> },

  {
    path: RouterItemEnum.notification,
    element: <NotificationPage />,
  },
  {
    path: RouterItemEnum.stateMent,
    element: (
      <TitlePageLayout title={t("activity")}>
        <StateMentMain />
      </TitlePageLayout>
    ),
  },
  {
    path: RouterItemEnum.profile,
    element: <ProfileMainPage />,
  },
  {
    path: AboutUsPagerouteName,
    element: <AboutUsPage />,
  },
  {
    path: ProfileInputrouteName,
    element: <ProfileInput />,
  },
  {
    path: EditProfilerouteName,

    element: <EditProfile />,
  },
  {
    path: TenorAmountSelectionRouteName,
    element: <TenorAmountSelection />,
  },
  {
    path: AddressCompleterouteName,
    element: <AddressComplete />,
  },

  makePath(RouterItemEnum.More, <MorePage />),
  {
    path: AboutUsPagerouteName,
    element: <AboutUsPage />,
  },
  // makePath('test', <TestRoute />),
  // makePath("payment", <PaymentSchedule />),

  makePath(ReportPagerouteName, <ReportPage />),
  makePath(OfferPagerouteName, <OfferPage />),
  makePath(FaqPagerouteName, <FaqPage />),

  makePath(RouterItemEnum.HowItWorksDana, <HowDanaWorks />),
  makePath(RouterItemEnum.WhatisCreditScore, <WhatIsCreditScore />),
  {
    path: "/init/:h/:cid",
    errorElement: (
      <div>
        <p className="text-center text-2xl">Somethings Went Wrong</p>
        <Link to="/" className="text-center text-red-500">
          Go to Home
        </Link>
      </div>
    ),

    element: <InitRoute />,
  },
];
