import { FC, PropsWithChildren, useRef } from "react";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import { Score } from "../../pages/NewHome/components/score";
import BottomNav from "../shared/Footer/Footer";

export interface ISheetNavLayoutProps extends PropsWithChildren {}

const SheetNavLayout: FC<ISheetNavLayoutProps> = ({ children }) => {
  const sheetRef = useRef<BottomSheetRef>(null);
  return (
    <section>
      <Score />
      <BottomSheet
        open
        blocking={false}
        ref={sheetRef}
        snapPoints={({ maxHeight }) => [maxHeight * 0.91]}
      >
        {children}
      </BottomSheet>
      <BottomNav />
    </section>
  );
};
export default SheetNavLayout;
