import { SliceType } from "../../../../state";
import { IFormFieldShape, Value } from "./types/multi";

export interface IScoreForm {
    currentShowFields : IFormFieldShape[]
    allFieldsFromApi : IFormFieldShape[],
    initialValues : Record<string,any>,
    setAllFieldsFromApi : (by : IFormFieldShape[]) => void,
    setCurrentShowFields : (by: IFormFieldShape[]) => void,
    mutateFormField:(forField: Value|undefined,unregisterFunc:(hideSlugs: string[]) => void) => void,

}

function removeElementsFromArray<T>(firstArray: T[], secondArray: T[]): T[] {
    // Create a Set from the elements of the second array for faster lookup
    const elementsToRemove = new Set(secondArray);
  
    // Filter out elements that are present in the second array from the first array
    const resultArray = firstArray.filter((item) => !elementsToRemove.has(item));
  
    return resultArray;
  }
const createFormStateSlice: SliceType<IScoreForm> = (set,get) => ({
    currentShowFields : [],
    allFieldsFromApi : [],
    initialValues : {

    },
    setAllFieldsFromApi(by){
        set(state => void (state.allFieldsFromApi = by))
    },
    setCurrentShowFields(by){
        set(state => void (state.currentShowFields = by))
    },
    mutateFormField(value,unregisterFunc){
        if(!value) return;
        const allFields = get().allFieldsFromApi;
  
            // map value dependency show id to fields
        let showFields = value.dependency.show.map(s=>allFields.find(field => field.id === s)!);
      
       
        let currentShowFields = [...get().currentShowFields,...showFields];
        
            // map value dependency hide id to fields slug
        if(!Boolean(value.dependency.hide)){
            currentShowFields = currentShowFields.sort((a,b) => +a.priority - +b.priority);
            // set currentShowFields
            set(state => void (state.currentShowFields = Array.from(new Set(currentShowFields))))
            return;
        }
       
        let hideFields = value.dependency.hide.map(h=>allFields.find(field => field.id === h)!);
        console.log(hideFields,"hideFields")
        if(hideFields){

            unregisterFunc(hideFields.map(h=>h?.slug));
        }
            
        currentShowFields = removeElementsFromArray(currentShowFields,hideFields);
        // add showFields to currentShowFields
        // let currentShowFields = [...get().currentShowFields,...showFields];
        // remove hideFields from currentShowFields
        // console.log(currentShowFields,value.dependency.hide,"hide",value.dependency.show,"show",value.dependency)
        // currentShowFields = currentShowFields.filter(field => !value.dependency.hide.includes(field?.id));
        // unregisterFunc(hideFields)
        // sort currentShowFields by priority
        currentShowFields = currentShowFields.sort((a,b) => +a.priority - +b.priority);

        // set currentShowFields
        set(state => void (state.currentShowFields = Array.from(new Set(currentShowFields))))
        

    }


})

export default createFormStateSlice;