import { FC } from "react";
import { useInitializeAndHandlePayment } from "../components/hooks/useInitalize";
import AsyncValue from "../modules/shared/AsyncValue";
import BtnPrimary from "../modules/shared/BtnPrimary";
import Loading from "../modules/shared/Loading";
import { UserInfo } from "../state";

import { useNavigate } from "react-router-dom";

const HolderPage: FC = () => {
  const { verifyRes } = useInitializeAndHandlePayment();
  const navigate = useNavigate();

  return (
    <div>
      <AsyncValue<UserInfo, Error>
        res={verifyRes}
        onSuccess={(data: UserInfo) => {
          return (
            <div className="flex flex-col justify-center items-center space-y-2  min-h-[100vh]">
              <Loading dontTakefullPageWidth />
              <h3>Processing ....</h3>
              <p className="mx-4 text-sm">Please wait or you can go to home</p>
              <BtnPrimary onClick={() => navigate("/home", { replace: true })}>
                Back To Home
              </BtnPrimary>
            </div>
          );
        }}
      />
    </div>
  );
};

export default HolderPage;

// https://test.dana.money/cp/api/loan/repay/pg/nagad/complete/?
// merchant=683002007104225&order_id=1418TS074906&payment_ref_id=MTEyMTA3NDkwNjUxOC42ODMwMDIwMDcxMDQyMjUuMTQxOFRTMDc0OTA2LjEwY2FiOGE5NmRmNjRmNWVmZjJm&status=Aborted&status_code=9999&message=Not%20a%20Nagad%20account
