import { FC, useState } from "react";
import useBoundStore from "../../../state/store";

import { TextInput } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import TitlePageLayout from "../../../components/Layouts/TitlePageLayout";
import { useRequestForMutation } from "../../../modules/hook/useRequest";
import BtnPrimary from "../../../modules/shared/BtnPrimary";
import { ProfileHeaders } from "./ProfileMain";

export const EditProfilerouteName = "/edit-profile-route";

export interface IEditProfileProps {}

const EditProfile: FC<IEditProfileProps> = ({}) => {
  const { profileInfo } = useBoundStore();
  const navigate = useNavigate();
  const [name, setName] = useState(profileInfo?.name ?? "");
  const [email, setemail] = useState(profileInfo?.email ?? "");
  const { trigger, isMutating } = useRequestForMutation("/user");
  return (
    <TitlePageLayout title="Edit Profile" dontShowBottomNav>
      <div className="h-[5vh]" />

      <ProfileHeaders data={profileInfo!} inEditMode />
      <div className="flex flex-col mx-6 space-y-6">
        <TextInput
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder="Full name.. "
        />
        <TextInput
          value={email}
          onChange={(e) => {
            setemail(e.target.value);
          }}
          placeholder="Your email.. "
        />

        <BtnPrimary
          onClick={() => {
            trigger(
              {
                postBody: {
                  name,
                  email,
                },
              },
              {
                onSuccess() {
                  navigate(-1);
                },
              }
            );
          }}
        >
          Submit
        </BtnPrimary>
      </div>
    </TitlePageLayout>
  );
};
export default EditProfile;
