import { Card, CardContent, CardHeader } from "../../components/Card";
export function ReportCard({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  return (
    <Card className="m-4 rounded-xl">
      <CardHeader>
        <p className="p-2 text-sm bg-green-100 max-w-fit rounded-xl">
          Doing Great
        </p>
      </CardHeader>
      <CardContent>
        <p className="text-lg font-bold">{title}</p>
        <div className="h-2"></div>
        <p className="text-gray-500">{content}</p>
      </CardContent>
    </Card>
  );
}
