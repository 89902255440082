import { FC } from "react";
import SheetNavLayout from "../../../components/Layouts/SheetNavLayout";
import { ReportCard } from "./../ReportCard";

export const ReportPagerouteName = "report--route";

export interface IReportPageProps {}

const ReportPage: FC<IReportPageProps> = () => {
  return (
    <SheetNavLayout>
      <section>
        <ReportCard
          title="No short term credit"
          content="You haven't withdrawn cash at all in the past 6 months"
        />
        <ReportCard
          title="Keep Your Balance Positive"
          content="You haven't applied for any high-interest short-term loans in the past 6 months"
        />
        <ReportCard
          title="Keep your cash withdraws low"
          content="Your account has been negative once or fewer times in the last 6 months"
        />
        <div className="h-12"></div>
      </section>
    </SheetNavLayout>
  );
};
export default ReportPage;
