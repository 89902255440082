import { FC } from 'react';
import SvgAnimation from '../../../components/shared/SvgAnimation';
import NotFoundAnimation from '../../../assets/lottie/404.json'
export interface INotFoundErrorPageProps {
}

const NotFoundErrorPage: FC<INotFoundErrorPageProps> = ({ }) => {
    return (
        <div className='h-[100vh] w-full flex items-center justify-center'>
            <SvgAnimation animationData={NotFoundAnimation} />
        </div>
    );
}
export default NotFoundErrorPage
