import { SliceType } from "../../../state";

import { EKYCContext, NidData, NidOcr } from "../types";

export interface IEkycSlice {
    nidData?: NidOcr,
    setNidData: (data: NidOcr) => void,
    nidDetails?: NidData,
    niderr?: any,
    ekyccontext: EKYCContext,
    setEkycContext: (by: EKYCContext) => void,

    setNidDetails: (by: NidData) => void,
}

const createEkycSliceSlice: SliceType<IEkycSlice> = (set) => ({
    nidData: undefined,
    ekyccontext: EKYCContext.BNPL,
    setEkycContext(by) {
        set(state => void (state.ekyccontext = by))
    },
    setNidDetails: (by) => {
        set(state => void (state.nidDetails = by))
    },
    setNidData: (data) => {
        set(state => void (state.nidData = data))
    },
})

export default createEkycSliceSlice;