import { Alert } from "flowbite-react";
import { FC, ReactNode } from "react";
import { SWRResponse } from "swr";
import { SWRMutationResponse } from "swr/mutation";
import ErrorAnim from "../../assets/lottie/error.json";
import SvgAnimation from "../../components/shared/SvgAnimation";
import Loading from "./Loading";
import NoItemFound from "./NoItemFound";

export interface IAsyncValueProps<D, E> {
  res: SWRResponse<D | any, E>;
  onLoading?: JSX.Element;
  onError?: (e: E) => JSX.Element;
  noItemMsg?: ReactNode;
  onSuccess: (data: D) => JSX.Element;
}

const ErrorElement: FC<{ error: any }> = ({ error }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[100vh]">
      <SvgAnimation animationData={ErrorAnim} loop={false} />

      <Alert color={"failure"}>{error?.message}</Alert>
    </div>
  );
};
export interface IAsyncMutationProps<D, E> {
  res:
    | SWRMutationResponse<D | any, E>
    | Omit<SWRMutationResponse<D | any, E>, "trigger">;
  onLoading?: JSX.Element;
  onError?: (e: E) => JSX.Element;
  noItemMsg?: ReactNode;
  onSuccess: (data: D) => JSX.Element;
}

export function AsyncMutation<D, E>(
  args: IAsyncMutationProps<D, E>
): JSX.Element {
  const { data, error, isMutating } = args.res;
  if (isMutating) {
    return <Loading />;
  }
  if (error) {
    return args.onError ? args.onError(error) : <ErrorElement error={error} />;
  }
  if (!error && data instanceof Array) {
    if (data.length === 0) {
      return <NoItemFound message={args.noItemMsg} />;
    }
  }
  if (!error && !data) {
    return <></>;
  }
  return args.onSuccess(data!);
}

function AsyncValue<D, E>(args: IAsyncValueProps<D, E>): JSX.Element {
  const { data, error, isValidating } = args.res;
  if (isValidating) {
    return <Loading />;
  }

  if (error) {
    return args.onError ? args.onError(error) : <ErrorElement error={error} />;
  }
  if (!error && data instanceof Array) {
    if (data.length === 0) {
      return <NoItemFound message={args.noItemMsg} />;
    }
  }
  if (!error && !isValidating) {
    return args.onSuccess(data ?? []);
  }
  if (!error && !data) {
    return args.onLoading ? args.onLoading : <Loading />;
  }
  return args.onSuccess(data!);
}

export const AsyncMutationRequest = <D, E>(args: {
  res:
    | SWRMutationResponse<D | any, E>
    | Omit<SWRMutationResponse<D | any, E>, "trigger">
    | SWRResponse<D | any, E>;
  onLoading?: JSX.Element;
  dontTakefullPageWidth?: boolean;
  onError?: (e: E) => JSX.Element;
  noItemMsg?: ReactNode;
  onIdle?: JSX.Element;
  onSuccess: (data: D) => JSX.Element;
}) => {
  const { data, error } = args.res;
  console.log(args.res);

  if ("isMutating" in args.res && args.res.isMutating) {
    return (
      args.onLoading || (
        <Loading dontTakefullPageWidth={args.dontTakefullPageWidth} />
      )
    );
  }
  if ("isLoading" in args.res && args.res.isLoading) {
    return (
      args.onLoading || (
        <Loading dontTakefullPageWidth={args.dontTakefullPageWidth} />
      )
    );
  }

  if (error) {
    return args.onError ? args.onError(error) : <ErrorElement error={error} />;
  }
  if (!error && data instanceof Array) {
    if (data.length === 0) {
      return <NoItemFound message={args.noItemMsg} />;
    }
  }
  if (!error && !data) {
    return args.onIdle ?? <></>;
  }
  return args.onSuccess(data!);
};

export default AsyncValue;
