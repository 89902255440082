import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { useGetHttpClient } from "../../../client/axios";
import { IUserConfig } from "../../../components/hooks/useConfig";
import { ICommonUserStatus } from "../../../modules/score/types";
import useBoundStore from "../../../state/store";
import { ProfileInputrouteName } from "../../Profile/pages/ProfileInput";
import { IProfileMain } from "../../Profile/types";
import { ILastApplicationResponse } from "../types";

export const useFetchUserScore = () => {
  const { setUserStaus, setProfileInfo, setUserConfig, setUserLastApply } =
    useBoundStore();
  const client = useGetHttpClient();
  const navigator = useNavigate();

  return useSWR(
    "fetchUserinfo",
    async () => {
      const userStatus = await client<ICommonUserStatus>("/user/status");
      const usrConfigStatus = client<IUserConfig>("/config/user");
      const usrProfileStatus = client<IProfileMain>("/user");
      const userLastApplication = client<ILastApplicationResponse>(
        "/integration/iwoca/latest_application"
      );

      try {
        const [usrConfigData, usrProfileData, userLastApply] =
          await Promise.all([
            usrConfigStatus,
            usrProfileStatus,
            userLastApplication,
          ]);
        return {
          userStatus,
          usrConfigData,
          usrProfileData,
          userLastApply,
        };
      } catch (error: any) {
        if (error instanceof Error) {
          throw new Error(error.message);
        }
        throw new Error(error);
      }
    },
    {
      onSuccess({ userStatus, usrConfigData, usrProfileData, userLastApply }) {
        setUserStaus(userStatus);
        setProfileInfo(usrProfileData);
        setUserConfig(usrConfigData);
        setUserLastApply(userLastApply);
        if (!usrProfileData.name) {
          navigator(ProfileInputrouteName);
        }
      },
      onError(err, key) {
        console.log("err", err);
        console.log("key", key);
      },
      focusThrottleInterval: 30000,
      dedupingInterval: 5000,
    }
  );
};
