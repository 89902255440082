
import { swrMainFetcher } from '../../../client/axios';
import { fetchProduct, loanCharge } from '../service';
import { ICategories, IProduct, ITenureDetails } from '../types';
import { SliceType } from '../../../state/store'
import { shuffleArray } from '../../../router/utils';

interface IProductFilterParams {
    featured?: boolean
    limit?: number
    offset?: number
    min?: string
    max?: string
    category_id?: string,
    category_name?: string,
    name?: string
    search?:string
}


export interface IPaylaterSlice {
    selectProduct?: IProduct
    tenure?: ITenureDetails
    isTenureLoading: boolean
    address?: string
    hasTenorError?: string
    filterProducts: IProduct[]
    filterParmas: IProductFilterParams,
    setFilterParams: (params: IProductFilterParams) => void
    isFilterProductLoading: boolean
    categoriesOfProduct: ICategories[]
    setTenore: (tenure: ITenureDetails) => void
    fetchProductCategories: () => Promise<void>
    setFilterProduct: (products: IProduct[]) => void
    isFilterEnable: boolean,
    toggleFilterEnablity: (fil?: boolean) => void,
    offset: number,
    productFetchDone: boolean,
    isProductFetching: boolean,

    fetchfilterProduct: (params: IProductFilterParams, concat?: boolean, offset?: number, cancleOffset?: boolean) => Promise<void>
    fetchTenureByMonth: (month: string) => Promise<void>
    setAddressForPaylater: (addr: string) => void
    setOffset: (num: number) => void
    mutateSelectProduct: (product: IProduct) => void
}

const createPaylaterSliceSlice: SliceType<IPaylaterSlice> = (set, get) => ({
    selectProduct: undefined,
    tenure: undefined,
    isTenureLoading: false,
    isProductFetching: false,
    isFilterEnable: false,
    address: undefined,
    filterParmas: {limit: 200},
    setFilterParams(params) {
        set(function (state) {
            state.filterParmas = params
        })
    },
    categoriesOfProduct: [],
    nerf: '',
    productFetchDone: false,

    filterProducts: [],
    setTenore(tenure) {
        set(function (state) {
            state.tenure = tenure
        })
    },


    toggleFilterEnablity(fil) {
        set(function (state) {
            state.isFilterEnable = fil ?? !get().isFilterEnable
        })
    },
    async fetchProductCategories() {
        
        const res = await swrMainFetcher<ICategories[]>('/bnpl/products/categories', get().userInfo?.token)
        set(function (state) {
            state.categoriesOfProduct.push(...res!)
        })
    },
    offset: 0,
    setOffset(num) {
        set(function (state) {
            state.offset = num
        })
    },

    isFilterProductLoading: false,
    setFilterProduct(product) {
        set(function (state) {
            state.filterProducts = product
        })
    },
    async fetchfilterProduct(params, concat = false, offset, cancleOffset,) {
       

        cancleOffset ? set(function (state) {
            state.offset = 0
            state.isFilterProductLoading = true
        }) : set(function (state) {
            // state.offset = get().offset + 100
            state.isFilterProductLoading = true
            state.productFetchDone = false
        })
        const product = await fetchProduct({ ...params, offset: offset ?? get().offset })
        if(!cancleOffset){
            set(function (state) {
                state.offset = get().offset + 100
            })
        }
        if(product?.length === 0){
            set(function (state) {
                state.productFetchDone = true
            })
        }else{
            set(function (state) {
                state.productFetchDone = false
            })
        }
        product && concat && set(function (state) {
            state.filterProducts.push(...shuffleArray(product))
            state.isFilterProductLoading = false
        })
        product && !concat && set(function (state) {
            state.filterProducts = product
            state.isFilterProductLoading = false
        })
        
    },
    setAddressForPaylater(addr) {
        set(function (state) {
            state.address = addr
        })
    },
    fetchTenureByMonth: async (month) => {
        set(function (state) {
            state.isTenureLoading = true
        })

        const charge = await swrMainFetcher<ITenureDetails>('/bnpl/products/charges', get().userInfo?.token, {
            config: {
                params: {
                    product_id: get().selectProduct?.id,
                    tenor: month
                }
            }
        }).catch((e) => {
            set(function (state) {
                state.hasTenorError = e.message
            })
        }).finally(() => {
            set(function (state) {
                state.isTenureLoading = false
            })
        })
        charge && set(function (state) {
            state.tenure = charge
            state.hasTenorError = undefined
        })
    },
    mutateSelectProduct(product) {
        set(state => void (state.selectProduct = product))
    },
})

export default createPaylaterSliceSlice;