import { Select, SelectItem } from "@nextui-org/react";
import { useFormikContext } from "formik";

export function AddressSelect({
  data,
  onSelect,
}: {
  data: Record<string, any>[];
  onSelect: (e: Record<string, any>) => void;
}) {
  const { setFieldValue } = useFormikContext();
  return (
    <Select
      name="address"
      key="address"
      label={"Address"}
      isRequired={true}
      classNames={{
        base: "my-2",
        label: "pb-8 font-bold",
      }}
      placeholder={"Select Address"} // {...props.fieldProps}
      onChange={(e) => {
        const selectAddress = data[parseInt(e.target.value)];
        onSelect(selectAddress);

        Object.keys(selectAddress).forEach((key) => {
          setFieldValue(key, selectAddress[key]);
        });
      }}
    >
      {data.map((v, i) => (
        <SelectItem key={i} value={i}>
          {v["line_1"] + " " + v["line_2"] + " " + v["line_3"]}
        </SelectItem>
      ))}
    </Select>
  );
}
