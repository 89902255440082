import { useGA4React } from "ga-4-react";
import React from "react";
import { useLocation } from "react-router-dom";
import analytics from "./utils";

export default function useGoogleAnalytics() {
  const location = useLocation()


  React.useEffect(() => {
    analytics.init()

  }, [])

  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    console.log("currentPath", currentPath)
    analytics.sendPageview(currentPath)
  }, [location])


}


