import { Alert, Modal } from "flowbite-react";
import Slider from "rc-slider";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useswrImmutable from "swr/immutable";
import { useModuleUserEvent } from "../../../analytics/events";
import { RadioWithLabel } from "../../../components/shared/Form/RadioWithLabel";
import { RouterItemEnum, makeRoute } from "../../../router/path";
import useBoundStore from "../../../state/store";
import { getPriceWithComma } from "../../../utils/en2bn";
import {
  useRequestForMutation,
  useRequestGet,
  useRequestGetForMutation,
} from "../../hook/useRequest";
import AsyncValue from "../../shared/AsyncValue";
import BtnPrimary from "../../shared/BtnPrimary";
import Loading from "../../shared/Loading";
import { ReactComponent as LoanApplyIcon } from "../assets/loan_apply.svg";
import { MonthOption } from "../components/TenureAndMonth";
import { ILoanCharge } from "../types";

interface ITenorSelectionProps {
  options?: number[];
  onChange: (val: number) => void;
  setValue: Dispatch<SetStateAction<number>>;
}
export const TenorSelectionForCashLoan: FC<ITenorSelectionProps> = ({
  options = [6, 9, 12, 24],
  onChange,
}) => {
  const [month, setMonth] = useState<number>(options[0]);
  useEffect(() => {
    onChange(month);
  }, [month, onChange]);
  return (
    <div className="m-2">
      <p>Loan Duration (months)</p>
      <div className="h-2"></div>
      <div className="flex justify-around">
        {options.map((val, index) => (
          <MonthOption
            optionTitle={`${val}`}
            key={index}
            isActive={val === month}
            onClick={() => {
              setMonth(val);
              onChange(val);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const LoanSelector = (settings: any) => {
  const sendGA = useModuleUserEvent("Digital Loan", "Applied");
  const { t } = useTranslation("loan");
  const { t: tc } = useTranslation();
  const { primaryColor } = useBoundStore();
  const [amount, setLoanAmount] = useState<number | number[]>(
    parseInt(settings["min"] ?? "1000")
  );
  const { trigger, isMutating, data, error } =
    useRequestGetForMutation(`/loan/charge`);
  const [tenor, setTenor] = useState<number>(3);
  const navigate = useNavigate();
  const applyLoan = useRequestForMutation("/loan/apply");
  const [showModal, setShowModal] = useState(false);
  const [purpose, setPurpose] = useState<string>("");
  const loanPurposeReq = useRequestGet("/loan/purposes");

  const handleLoanAgree = () => {
    sendGA(`Loan Amount: ${amount} Tenor: ${tenor} Purpose: ${purpose}`);
    applyLoan.trigger(
      {
        postBody: {
          amount,
          tenor,
          purpose,
        },
      },
      {
        onSuccess: (data) => {
          navigate(
            makeRoute([
              RouterItemEnum.common,
              RouterItemEnum.thankYou,
              `?content=${t(
                "Your_application_is_being_processed_by_Bank.You_will_be_notified_of_the_credit_decision_soon._If_you_don’t_get_any_update_by_48_hours_please_call"
              )} `,
            ]),
            { replace: true }
          );
        },
      }
    );
  };

  return (
    <>
      <div className="mx-4">
        <div className="h-10"></div>
        <p className="text-center font-semibold text-gray-600 text-lg ">
          {t("select_your_loan_amount")}
        </p>
        <div className="h-4"></div>

        <div className="flex items-center justify-center">
          <p className="inline-block text-center rounded-md p-2 bg-gray-100 shadow-md">
            {getPriceWithComma(amount)}
          </p>
        </div>
        <div className="h-6" />
        {applyLoan.error && (
          <Alert color="failure">{applyLoan.error.message}</Alert>
        )}
        {applyLoan.isMutating && <Loading dontTakefullPageWidth />}

        <Slider
          onAfterChange={(val) => {
            setLoanAmount(val);
            if (!tenor) setTenor(3);

            trigger({
              config: {
                params: {
                  amount: val,
                  tenor,
                  type: "cash",
                },
              },
            });
          }}
          // railStyle={{
          //     background: `linear-gradient(to right,  ${primaryColor} 20%,${primaryColor} 80%)`
          // }}
          trackStyle={{
            background: `linear-gradient(to right,  ${primaryColor} 20%,${primaryColor} 80%)`,
          }}
          handleStyle={{
            background: `linear-gradient(to right,  ${primaryColor} 20%,${primaryColor} 80%)`,
          }}
          min={parseInt(settings["min"])}
          step={parseInt(settings["step"])}
          max={parseInt(settings["max"] ?? "10000")}
          onChange={(val) => setLoanAmount(val)}
          value={amount}
        />

        <div className="flex">
          <div className="flex-1 text-left text-gray-400">
            {getPriceWithComma(settings["min"])}
          </div>
          <div className="flex-1 text-right text-gray-400">
            {getPriceWithComma(settings["max"] ?? "10000")}
          </div>
        </div>
        <Modal show={showModal} onClose={() => setShowModal(!showModal)}>
          <div className="h-4"></div>
          <Modal.Body>
            <div className="flex flex-col justify-center items-center">
              <LoanApplyIcon />
              <div className="h-2"></div>
              <p className="text-xl font-semibold">{t("purpose_of_loan")}</p>
              <div className="h-3"></div>
              <AsyncValue
                res={loanPurposeReq}
                onSuccess={function (data: string[]): JSX.Element {
                  return (
                    <RadioWithLabel
                      onChange={(e) => setPurpose(e.target.value)}
                      label={""}
                      horizontal
                      name={"purpose"}
                      values={data}
                    />
                  );
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex w-full justify-evenly ">
              <div
                className="btn bg-pink-800"
                onClick={() => setShowModal(false)}
              >
                {tc("cancel")}
              </div>
              <BtnPrimary
                disable={purpose === ""}
                onClick={() => {
                  handleLoanAgree();
                  setShowModal(false);
                }}
              >
                {tc("apply")}
              </BtnPrimary>
            </div>
          </Modal.Footer>
        </Modal>

        <TenorSelectionForCashLoan
          setValue={setTenor}
          options={settings["tenors"]}
          onChange={(val) => {
            setTenor(val);
            trigger({
              config: {
                params: {
                  amount,
                  tenor: val,
                  type: "cash",
                },
              },
            });
          }}
        />

        {/* <LoanSlider /> */}
        {isMutating ? (
          <Loading dontTakefullPageWidth />
        ) : (
          data && <LoanProcessCard {...data} />
        )}
        {error && <p className="text-red-500">{error.message}</p>}
        <div className="h-4"></div>
        <BtnPrimary center onClick={() => setShowModal(!showModal)}>
          {tc("proceed")}
        </BtnPrimary>
      </div>
    </>
  );
};

const LoanProcess: FC = () => {
  const loanSetting = useswrImmutable("/settings/loan");

  return (
    <>
      <AsyncValue<any, any>
        res={loanSetting}
        onSuccess={function (data: any): JSX.Element {
          return <LoanSelector {...data} />;
        }}
      />
    </>
  );
};

export const LoanProcessCard = (loanCharge: ILoanCharge) => {
  const { t } = useTranslation("loan");
  return (
    <div className="my-4 mx-2">
      <div className="text-gray-400 px-6 py-4 border-r-2 shadow-md border-t-2 rounded-xl">
        <div className="flex justify-between">
          <p>{t("monthly__emi")} :</p>
          <p className="text-teal-600">
            {" "}
            {getPriceWithComma(loanCharge.monthly_emi)}
          </p>
        </div>
        <div className="flex justify-between">
          <p>{t("interest")}:</p>
          <p className="text-teal-600">{loanCharge.interest_text}</p>
        </div>
        <div className=" border-t-2 border-dashed my-2"></div>
        <div className="flex justify-between">
          <p>{t("processing__fee")}:</p>
          <p className="text-teal-600">{getPriceWithComma(loanCharge.fee)}</p>
        </div>
        <div className="flex justify-between">
          <p>{t("documentation_charge")}:</p>
          <p className="text-teal-600">
            {getPriceWithComma(loanCharge.fee_details["documentation_fee"])}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanProcess;
